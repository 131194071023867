import {
  setNibrsErrors,
  setValidationErrors,
  getCityIdFromCityName,
  SET_SELECTED_INCIDENT_EVIDENCE,
  GET_AFTER_ACTION_REPORT,
  setSelectedActionReport,
} from 'reducers/IncReducer';
import { v4 as uuidv4 } from 'uuid';
import store from '../../config/configureStore';

import { ADD_PARTIES, ADD_PROPERTIES, setIncRelationsData } from '../IncReducer';
import { displayDateTime, formatSaveDate, getDatabaseDateTimeFormat } from '../TimeReducer';
import {
  asyncForEach,
  getDescriptionofCode,
  getJuvenileReportValues,
  formatPersonFullName,
} from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { processPartyPersonData } from './partyPersonHelper';
import { getService } from 'reducers/service';
import { setNibrsErrorsForIncident } from './uiHomeHelper';

import {
  generateHandleEditOffense,
  generateHandleEditIncident,
} from 'simpl-schema-validation/helpers/generateHandleEdit';

export const assignTempIncidentId = () => {
  return uuidv4();
};

export const getIncident = async () => {
  const client = store.store.getState().websocket.websocket;
  const incident = store.store.getState().incident;
  if (!client) return;
  try {
    const service = client.service('rms-incident');
    service.timeout = 200000;
    let result = await service.get(incident.ptsIncidentId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removeIncident = async () => {
  const client = store.store.getState().websocket.websocket;
  const incident = store.store.getState().incident;
  if (!client) return;
  try {
    const service = client.service('rms-incident');
    service.timeout = 20000;
    const result = await service.remove(incident.ptsIncidentId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateJuvenileStatusIncident = async (id, flag) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('rms-incident');
    service.timeout = 20000;
    const result = await service.update(id, { IsJuvenileReport: flag });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removePropertyStorage = async (ptsPropertyStorageId) => {
  if (ptsPropertyStorageId) {
    const wsClient = store.store.getState().websocket.websocket;
    const service = wsClient.service('ptspropertystorage');
    service.timeout = 20000;

    await service.patch(ptsPropertyStorageId, { IsDeleted: true });
  }
};

export const removeEntity = async (entity, data, dispatch) => {
  const wsClient = store.store.getState().websocket.websocket;
  const definition = {
    incidents: {
      service: 'rms-incident',
      key: 'ptsIncidentID',
      includeIncId: false,
    },
    actionReport: {
      service: 'rms-action-report',
      key: 'ptsIncAfterActionID',
      includeIncId: false,
    },
    addresses: {
      service: 'inclocationaddress',
      key: 'ptsAddressID',
      includeIncId: false,
    },
    partyplace: {
      service: 'incincidentpartyplace',
      key: 'ptsIncPlaceID',
      includeIncId: false,
    },
    parties: {
      service: 'incincidentpartyperson',
      key: 'ptsIncPersonID',
    },
    properties: {
      service: 'incincidentproperty',
      key: 'incIncidentPropertyID',
    },
    offenses: {
      service: 'incoffense',
      key: 'ptsOffenseID',
    },
    narratives: {
      service: 'ptsnarrative',
      key: 'ptsNarrativeID',
      includeIncId: false,
    },
    evidence: {
      service: 'incevidence',
      key: 'ptsEvidenceID',
    },
    incsubject: {
      service: 'incsubject',
      key: 'ptsSubjectID',
    },
    incvictim: {
      service: 'incvictim',
      key: 'ptsVictimID',
    },
    incarrestee: {
      service: 'incarrestee',
      key: 'ptsArresteeID',
      includeIncId: false,
    },
    incproperty: {
      service: 'incproperty',
      key: 'ptsPropertyID',
      includeIncId: false,
    },
    incdvsuspect: {
      service: 'incdvsuspect',
      key: 'ptsDVSuspectID',
      includeIncId: false,
    },
    incevidencecustody: {
      service: 'incevidencecustody',
      key: 'ptsEvidenceCustodyID',
    },
    incevidencestorage: {
      service: 'incevidencestorage',
      key: 'ptsEvidenceStorageID',
    },
    zones: {
      service: 'inclocationzone',
      datbaseKey: 'Zone',
      formKey: 'zone',
    },
    dispositions: {
      service: 'incdispositions',
      datbaseKey: 'Disposition',
      formKey: 'disposition',
    },
    alerts: {
      service: 'incalerts',
      datbaseKey: 'ptsAlertID',
      formKey: 'ptsAlertId',
    },
    notifications: {
      service: 'incnotifications',
      datbaseKey: 'ptsNotificationID',
      formKey: 'ptsNotificationId',
    },
    tags: {
      service: 'rms-tags-v2',
      datbaseKey: 'ptsTagID',
      formKey: 'ptsTagId',
    },
  };

  try {
    const serviceName = definition[entity].service;
    const incident = store.store.getState().incident;
    const service = wsClient.service(serviceName);
    service.timeout = 20000;

    let query = {};

    if (['zones', 'dispositions', 'alerts', 'notifications', 'tags'].includes(entity)) {
      query = {
        [definition[entity].datbaseKey]: data[definition[entity].formKey],
        IsDeleted: false,
      };

      if (entity === 'zones') {
        query = {
          ...query,
          ptsLocationID: parseInt(incident.incident.ptsLocationId),
        };
      }
      if (entity === 'dispositions') {
        query = {
          ...query,
          ptsIncidentID: parseInt(incident.ptsIncidentId),
        };
      }
    } else {
      let dataKey = definition[entity].key.replace('ID', 'Id');
      const value = data[dataKey] ? data[dataKey] : data[definition[entity].key];

      if (!value) {
        return;
      }

      // Removing a offense should remove all nibrs errors that are connected to that offense
      // if (entity === 'offenses') {
      //   removeAllErrors(value, dispatch);
      // }

      query = {
        ptsIncidentID: parseInt(data.ptsIncidentId),
        [definition[entity].key]: value,
        IsDeleted: false,
      };

      if (definition[entity].includeIncId === false) {
        delete query.ptsIncidentID;
      }
    }

    if (definition[entity].query) {
      query = {
        ...query,
        ...definition[entity].query,
      };
    }

    await service.remove(null, { query });

    if (entity == 'parties') {
      await doJuvenileReportCheck(data);
    }
  } catch (error) {
    console.log(error);
  }
};
export const doJuvenileReportCheck = async (data) => {
  const { ptsIncPersonId, ptsIncidentId } = data;
  const incident = store.store.getState().incident;
  const incidentValues = incident?.incident?.values;
  const prevJuvenileReportValue = incidentValues?.IsJuvenileReport;
  const parties = incident?.parties || [];
  const filteredParties = parties.filter((item) => item.ptsIncPersonId !== ptsIncPersonId);

  const currentJuvenuileValue = getJuvenileReportValues(incidentValues, filteredParties);
  if (currentJuvenuileValue !== prevJuvenileReportValue) {
    // console.log(currentJuvenuileValue, prevJuvenileReportValue);

    await updateJuvenileStatusIncident(ptsIncidentId, currentJuvenuileValue);
  }
};
export const getEntitiesByIncIdV2 = async (incId, clearanceLevel) => {
  const wsClient = store.store.getState().websocket.websocket;
  if (!wsClient) return;
  const service = wsClient?.service('rms-incident-v2');
  service.timeout = 2000000;

  let response;
  await service
    .get({ id: incId, clearance: clearanceLevel })
    .then((res) => {
      response = res;
    })
    .catch((err) => console.log(err));

  return response;
};

// TODO: This function will be removed
export const getEntitiesByIncId = async (incId, entity, query = {}) => {
  const wsClient = store.store.getState().websocket.websocket;
  if (!wsClient) return;
  const service = wsClient?.service('rms-incident');
  service.timeout = 2000000;

  const definition = {
    parties: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      parties: true,
      ...query,
    },
    addresses: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      address: true,
      city: true,
      coordinate: true,
      ...query,
    },
    offenses: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      offenses: true,
      offender: true,
      victim: true,
      relation: true,
      arrestee: true,
      ...query,
    },
    properties: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      properties: true,
      item: true,
      firearm: true,
      jewelry: true,
      structure: true,
      substance: true,
      vehicle: true,
      ...query,
    },
    evidence: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      evidence: true,
      custody: true,
      storage: true,
      offense: true,
      disposition: true,
      ...query,
    },
    narratives: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      narrative: true,
      ...query,
    },
    general: {
      ptsIncidentID: incId,
      location: true,
      ...query,
    },
    dispositions: {
      ptsIncidentID: incId,
      dispositions: true,
      ...query,
    },
    zones: {
      ptsIncidentID: incId,
      location: true,
      ...query,
    },
    alerts: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      alerts: true,
      ...query,
    },
    notifications: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      notifications: true,
      ...query,
    },
    tags: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      tags: true,
      ...query,
    },
  };
  let response;
  await service
    .find({
      query: definition[entity],
    })
    .then((res) => {
      response = res;
    })
    .catch((err) => console.log(err));

  // If api returns only one data then just send that data to component.
  // Why? No specific reason yet.
  if (Array.isArray(response.data) && response.data.length === 1) {
    return response.data[0];
  }
  return response.data;
};

// Region - Short cut API
export const upsertEvidenceOffense = async (evId, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('incevidenceoffense');
    service.timeout = 20000;

    const exist = await service.find({
      query: {
        ptsEvidenceID: evId,
        ptsIncidentID: incidentID,
      },
    });

    if (exist.total > 0) {
      for (let i = 0; i < exist.data.length; i++)
        await service.patch(exist.data[i].ptsEvidenceOffenseID, {
          IsDeleted: true,
        });
    }
    offenseIDs.forEach(async (offenseID) => {
      const exist = await service.find({
        query: {
          ptsOffenseID: offenseID,
          ptsEvidenceID: evId,
          ptsIncidentID: incidentID,
        },
      });
      if (exist.total > 0) {
        for (let i = 0; i < exist.data.length; i++)
          await service.patch(exist.data[i].ptsEvidenceOffenseID, {
            IsDeleted: false,
          });
      } else {
        await service.create({
          ptsIncidentID: incidentID,
          ptsEvidenceID: evId,
          ptsOffenseID: offenseID,
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

const createCustomEvidenceNumber = async () => {
  const client = store.store.getState().websocket.websocket;
  const agencyID = store.store.getState().user.userAgency;

  const data = { EntityName: 'Evidence', AgencyID: agencyID };

  const service = client.service('custom-ids');
  service.timeout = 20000;

  const evidenceId = await service.create(data);

  if (evidenceId === 'Agency ID does not match.') return null;

  return evidenceId;
};

export const addNewEvidenceItem = async (form, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const AgencyID = store.store.getState().user.userAgency;

  if (!client) return;
  try {
    const service = client.service('rms-evidence');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      IncidentID: form.values.reportNumber,
      incIncidentPropertyID: form.values.incIncidentPropertyId,
      DaysInEvidence: 0,
      Category: form.values.category,
      EvidenceType: form.values.evidenceType,
      Description: form.values.description,
      LocationCollected: form.values.locationCollected,
      CollectedBy: form.values.collectedBy,
      DateTimeCollected: form.values.collectedDate,
      RequiredAction: form.values.requiredAction,
      ActionDate: form.values.actionDate,
      TemporaryLocation: form.values.temporaryLocation,
      Notes: form.values.notes,
      SubmittedBy: form.values.submittedBy,
      IsIncludeOnReport: false,
      DisposalCode: form.values.disposalCode,
      Quantity: form.values.quantity,
      TagNumber: form.values.tagNumber,
      Make: form.values.make,
      Model: form.values.model,
      SerialNumber: form.values.serialNumber,
      VIN: form.values.vin,
      LicenseNumber: form.values.licenseNumber,
      Condition: form.values.condition,
      SubstanceType: form.values.substanceType,
      SubstanceClass: form.values.substanceClass,
      Measurement: form.values.measurement,
      ClearanceLevel: form.values.evidenceClearanceLevel || '0',
      attachments: form.values.attachments,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
      SubmittedAt: form.values.submittedAt,
      AgencyID: AgencyID,
      Offenses: form.values?.offenses ? JSON.stringify(form.values?.offenses) : null,
      PropertyOwner: form.values?.propertyOwner || '',
      PropertyOwnerType: form.values?.propertyOwnerType || '',
      CurrentLocation: form.values?.currentLocation || '',
    });
    if (form.incIncidentPropertyId) {
      await updateIncIncidentProperty(form.incIncidentPropertyId, {
        IsEvidenceCreated: true,
        UpdatedBy: user,
      });
    }
    return result;
  } catch (e) {
    console.log(e);
  }
};
export const updateIncIncidentProperty = async (id, data) => {
  try {
    const service = getService('incincidentproperty');
    service.timeout = 20000;
    const result = await service.patch(id, data);
    console.log({ result });
  } catch (error) {
    console.log({ error });
  }
};
export const processDataForEvidenceCustody = (form, incidentID, evidenceID) => {
  const user = store.store.getState().user.userData?.user.Username;

  const obj = {
    ptsIncidentID: incidentID,
    ptsEvidenceID: evidenceID,
    Status: form.values.Status,
    CustodyFrom: form.values.custodyFrom,
    CustodyTo: form.values.custodyTo,
    Reason: form.values.reason,
    Notes: form.values.notes,
    RequiredAction: form.values.requiredAction,
    DisposalCode: form.values.disposalCode,
    TransactionDate: form.values.transactionDate,
    TransactionType: form.values.transactionType,
    IsLocked: false,
    IsSealed: false,
    IsDeleted: false,
    CreatedBy: user,
  };
  return obj;
};
export const addNewEvidenceCustodyBulk = async (data) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user;

  const currentUserFullname = formatPersonFullName(user);

  if (!client) return;
  try {
    const service = client.service('rms-evidence-custody');
    service.timeout = 20000;
    const result = await service.create(data, {
      query: { IsBulk: true, currentUserFullname: currentUserFullname },
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};
export const addNewEvidenceCustody = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user;

  const currentUserFullname = formatPersonFullName(user);

  if (!client) return;
  try {
    const service = client.service('rms-evidence-custody');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      Status: form.values.Status,
      CustodyFrom: form.values.custodyFrom,
      CustodyTo: form.values.custodyTo,
      Reason: form.values.reason,
      Notes: form.values.notes,
      RequiredAction: form.values.requiredAction,
      DisposalCode: form.values.disposalCode,
      TransactionDate: form.values.transactionDate,
      TransactionType: form.values.transactionType,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: currentUserFullname,
      ComeFromCheckInFlow: form.values.comeFromCheckInFlow || false,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewEvidenceDisposition = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-disposition');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      BypassDispAssumption: form.values.bypassDisposalAssumption,
      ReadyForDisposal: form.values.readyForDisposal,
      FinalDisposition: form.values.finalDisposition,
      DispositionDate: form.values.dispositionDate || null,
      DispositionNotes: form.values.dispositionNotes,
      DisposedOfBy: form.values.disposedOfBy,
      ReturnBy: form.values.returnBy?.ptsPersonID,
      ReturnDate: form.values.returnDate ? formatSaveDate(form.values.returnDate) : null,
      ReturnTo: form.values.returnTo?.ptsPersonID,
      HowDestroyed: form.values.howDestroyed?.Code || null,
      DestructionDate: form.values.destructionDate
        ? formatSaveDate(form.values.destructionDate)
        : null,
      DestructionReturn: form.values.destructionReturn,
      DestructionReturnDate: form.values.destructionReturnDate
        ? formatSaveDate(form.values.destructionReturnDate)
        : null,
      UpdatedBy: form.values.enteredBy,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      IsDisposed: form.values.isDisposed,
      CreatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewEvidenceStorage = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-storage');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      DateTimeIn: form.values.dateIn,
      StorageLocation: form.values.storageLocation,
      SubLocation: form.values.subLocation,
      Container: form.values.container,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });

    return {
      ptsEvidenceId: result?.ptsEvidenceID,
      ptsIncidentId: result?.ptsIncidentID,
      ptsEvidenceStorageId: result?.ptsEvidenceStorageID,
      values: {
        incidentId: result?.ptsIncidentID,
        evidenceId: result?.ptsEvidenceID,
        evidenceStorageId: result?.ptsEvidenceStorageID,
        dateIn: form.values.dateIn,
        storageLocation: result?.StorageLocation,
        container: result?.Container,
        subLocation: result?.SubLocation,
        enteredBy: result?.UpdatedBy,
        created: result?.Created,
        createdBy: result?.CreatedBy,
        updated: result?.Updated,
        updatedBy: result?.UpdatedBy,
      },
    };
  } catch (e) {
    console.log(e);
  }
};

export const addNewOffense = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offenses');
    service.timeout = 20000;
    const result = await service.create({
      CriminalActivity: form.values.criminalActivity,
      IncidentLocationType: form.values.locationCategory,
      BiasMotivations: form.values.biasMotivation,
      OffenderSuspectedOfUsing: form.values.offenderSuspectedOfUsing,
      WeaponForceType: form.values.forceCategory,
      IsGangInvolved: form.values.isGangInvolved,
      IsHomeInvasion: false,
      IsDomesticViolence: false,
      IsIdentityTheft: false,
      IsCargoTheft: form.values.cargoTheft || false,
      IsExcluded: form.values.excludeOffense || false,
      StructuresEntered:
        !isNaN(form.values.structuresEntered) &&
        form.values.structuresEntered !== '' &&
        form.values.structuresEntered !== null
          ? Number(form.values.structuresEntered)
          : null,
      StructuresOccupied: form.values.structuresOccupied || false,
      MethodOfEntry: form.values.methodOfEntry,
      statute: form.values.statuteDetails.Description,
      ptsStatuteID: form.values.statuteDetails.ptsStatuteID,
      ptsFBICode: form.values.statuteDetails.ptsFBICodeID,
      IsAttempted: form.values.offenseAttempted || false,
      ClearanceLevel: form.values.offenseClearanceLevel || '0',
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewNarratives = async (form, incidentID, dispatch) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-narratives');
    service.timeout = 20000;
    const result = await service.create({
      ParentType: 'INCIDENT',
      ptsParentID: incidentID,
      ptsAuthorID: form.values.authorId,
      NarrativeTitle: form.values.narrativeTitle,
      NarrativeSequence: Number(form.values.sequence),
      NarrativeBody: form.values.narrativeBody,
      NarrativeType: form.values.narrativeType,
      ApprovedBy: form.values.approvedBy,
      NarrativeDT: form.values.narrativeDT,
      ApprovedDT: form.values.approvedDT,
      ptsEntryClerkID: form.values.entryClerkId,
      EntryClerk: form.values.entryClerk,
      ClearanceLevel: form.values.narrativeClearanceLevel || '0',
      attachments: form.values.attachments,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log('error', error);
    dispatch(notify(error.message, 'error'));
  }
};

// After Action Section Begin

export const prepareActionReportReviewDetailsData = (review) => {
  const { codeReviewTypes, codeReviewFindings } = store.store.getState().dictionary;

  return {
    ptsIncAfterActionReviewId: review?.ptsIncAfterActionReviewID,
    ptsIncAfterActionId: review?.ptsIncAfterActionID,
    ptsIncidentId: review?.ptsIncidentID,

    reviewDetails: {
      ptsIncAfterActionReviewId: review?.ptsIncAfterActionReviewID,
      ptsIncAfterActionId: review?.ptsIncAfterActionID,
      ptsIncidentId: review?.ptsIncidentID,
      values: {
        ptsIncAfterActionReviewId: review?.ptsIncAfterActionReviewID,
        ptsIncAfterActionId: review?.ptsIncAfterActionID,
        ptsIncidentId: review?.ptsIncidentID,

        reviewType: review?.ReviewType || null,
        reviewTypeDescription: review?.ReviewType
          ? getDescriptionofCode(codeReviewTypes, review?.ReviewType)
          : null,
        reviewDateTime: displayDateTime(review?.ReviewDateTime) || null,
        isFinalReview: review?.IsFinalReview || null,
        reviewedBy: review?.ReviewedBy || null,
        reviewerFullName: review?.ReviewerFullName || null,
        reviewFinding: review?.ReviewFinding || null,
        reviewFindingDescription: review?.ReviewFinding
          ? getDescriptionofCode(codeReviewFindings, review?.ReviewFinding)
          : null,
        recommendedAction: review?.RecommendedAction || null,
        reviewComments: review?.ReviewComments || null,

        created: displayDateTime(review?.Created) || null,
        createdBy: review?.CreatedBy || null,
        updated: displayDateTime(review?.Updated) || null,
        updatedBy: review?.UpdatedBy || null,
      },
    },
  };
};

export const prepareActionReportPartyDetailsData = (party) => {
  const {
    codePartyRelationship,
    codeSex,
    codeRace,
    codeEthnicity,
  } = store.store.getState().dictionary;

  try {
    return {
      ptsIncAfterActionPartyId: party?.ptsIncAfterActionPartyID,
      ptsIncAfterActionId: party?.ptsIncAfterActionID,
      ptsIncidentId: party?.ptsIncidentID,
      ptsPersonId: party?.ptsPersonID || null,
      partyDetails: {
        ptsIncAfterActionPartyId: party?.ptsIncAfterActionPartyID,
        ptsIncAfterActionId: party?.ptsIncAfterActionID,
        ptsIncidentId: party?.ptsIncidentID,
        ptsPersonId: party?.ptsPersonID || null,
        values: {
          ptsIncAfterActionPartyId: party?.ptsIncAfterActionPartyID || null,
          ptsIncAfterActionId: party?.ptsIncAfterActionID || null,
          ptsIncidentId: party?.ptsIncidentID || null,
          ptsPersonId: party?.ptsPersonID || null,

          partyType: party?.PartyType || null,
          partyTypeDescription: party.PartyTypeDescription
            ? party.PartyTypeDescription
            : getDescriptionofCode(codePartyRelationship, party.PartyType),

          firstName: party?.FirstName || null,
          lastName: party?.LastName || null,
          middleName: party?.MiddleName || null,
          fullName: party?.FullName || null,
          age: party?.Age || null,
          height: party?.Height || null,
          weight: party?.Weight || null,
          race: party?.Race || null,
          sex: party?.Sex || null,
          ethnicity: party?.Ethnicity || null,
          sexDescription: party.SexDescription
            ? party.SexDescription
            : getDescriptionofCode(codeSex, party.Sex),
          raceDescription: party.RaceDescription
            ? party.RaceDescription
            : getDescriptionofCode(codeRace, party.Race),
          ethnicityDescription: party.EthnicityDescription
            ? party.EthnicityDescription
            : getDescriptionofCode(codeEthnicity, party.Ethnicity),

          officerYearOfService: party?.OfficerYearOfService || null,
          isOfficerFullTime: party?.IsOfficerFullTime || null,
          officerOnDuty: party?.OfficerOnDuty || null,
          officerReadilyIdentifiable: party?.OfficerReadilyIdentifiable || null,

          officerInjured: party?.OfficerInjured || null,
          officerAssignmentType: party?.OfficerAssignmentType || null,
          didOfficerDischargeFirearm: party?.DidOfficerDischargeFirearm || null,
          numberOfShotsFired: party?.NumberOfShotsFired || null,
          lowerDistanceFromOffender: party?.LowerDistanceFromOffender || null,
          higherDistanceFromOffender: party?.HigherDistanceFromOffender || null,
          officerWeapons: party?.OfficerWeapons || null,

          injuries: party?.Injuries || null,

          offenderImpaired: party?.OffenderImpaired || null,
          offenderImpairments: party?.OffenderImpairments || null,
          didOffenderResist: party?.DidOffenderResist || null,
          offenderResistanceUsed: party?.OffenderResistanceUsed || null,
          offenderThreatToward: party?.OffenderThreatToward || null,
          offenderArmed: party?.OffenderArmed || null,
          forceTypesToOffender: party?.ForceTypesToOffender || null,

          updatedBy: party?.UpdatedBy || null,
          updated: displayDateTime(party?.Updated) || null,
          createdBy: party?.CreatedBy || null,
          created: displayDateTime(party?.Created) || null,
        },
      },
    };
  } catch (err) {
    console.log(err);
  }
};

export const prepareActionReportDetailsData = (actionReport) => {
  const { codeActionReportStatus } = store.store.getState().dictionary;

  try {
    return {
      ptsIncAfterActionId: actionReport?.ptsIncAfterActionID || null,
      ptsIncidentId: actionReport?.ptsIncidentID || null,

      values: {
        ptsIncAfterActionId: actionReport?.ptsIncAfterActionID || null,
        ptsIncidentId: actionReport?.ptsIncidentID || null,
        ptsAddressId: actionReport?.ptsAddressID || null,

        ambushed: actionReport?.Ambushed || null,
        beginDatetime: displayDateTime(actionReport?.BeginDatetime) || null,
        condition: actionReport?.Condition || null,
        involvedMultiAgencies: actionReport?.InvolvedMultiAgencies || null,
        officerKilledOrAssaulted: actionReport?.OfficerKilledOrAssaulted || null,
        officerApproachedOffender: actionReport?.OfficerApproachedOffender || null,
        investigatedByAnotherAgency: actionReport?.InvestigatedByAnotherAgency || null,
        reasonOfInitialContact: actionReport?.ReasonOfInitialContact || null,
        seniorOfficerPresent: actionReport?.SeniorOfficerPresent || null,
        notes: actionReport?.Notes || null,
        totalOfficers: actionReport?.TotalOfficers || null,
        totalAgencyOfficers: actionReport?.TotalAgencyOfficers || null,
        totalOffenders: actionReport?.TotalOffenders || null,

        updated: displayDateTime(actionReport?.Updated) || null,
        updatedBy: actionReport?.UpdatedBy || null,
        created: displayDateTime(actionReport?.Created) || null,
        createdBy: actionReport?.CreatedBy || null,

        status: actionReport?.Status,
        statusDescription: actionReport?.Status
          ? getDescriptionofCode(codeActionReportStatus, actionReport?.Status)
          : null,
        actionClearanceLevel: String(actionReport?.ClearanceLevel),
        officerCompletingReport: actionReport?.OfficerCompletingReport || null,
        officerCompletingReportFullname: actionReport?.OfficerCompletingReportFullname || null,
        reportDatetimeForUiList: actionReport?.ReportDatetime || null,
        reportDatetime: displayDateTime(actionReport?.ReportDatetime) || null,
        involveMultiAgenciesComments: actionReport?.InvolveMultiAgenciesComments || null,
        investigatedByAnotherAgencyComments:
          actionReport?.InvestigatedByAnotherAgencyComments || null,
      },
    };
  } catch (err) {
    console.log(err);
  }
};

export const prepareActionReportData = (updatedAction) => {
  // action report details section
  let actionReport = {};

  actionReport.ptsIncAfterActionId = updatedAction?.ptsIncAfterActionID || null;
  actionReport.ptsIncidentId = updatedAction?.ptsIncidentID || null;

  actionReport.afterActionDetails = prepareActionReportDetailsData(updatedAction);

  // action report reviews section
  let actionReportReviews = [];

  if (updatedAction?.ActionReportReviews) {
    const { ActionReportReviews } = updatedAction;

    if (ActionReportReviews?.length) {
      // find final reviewed by
      let finalReviewedByWhom = null;

      ActionReportReviews.forEach((arr) => {
        if (arr?.IsFinalReview) {
          finalReviewedByWhom = arr?.ReviewerFullName || null;
        }
      });

      if (finalReviewedByWhom) {
        actionReport.afterActionDetails.values.finalReviewedBy = finalReviewedByWhom;
      }

      actionReportReviews = ActionReportReviews.map((review) => {
        return prepareActionReportReviewDetailsData(review);
      });
    }
  }

  actionReport.actionReportReviews = actionReportReviews;

  // action report parties section
  let actionReportParties = [];

  if (updatedAction?.ActionReportParties) {
    const { ActionReportParties } = updatedAction;

    if (ActionReportParties?.length) {
      actionReportParties = ActionReportParties.map((party) => {
        return prepareActionReportPartyDetailsData(party);
      });
    }
  }

  actionReport.actionReportParties = actionReportParties;

  return actionReport;
};

export const createAfterActionDetails = async (afterActionDetailsForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const ptsIncidentId = store.store.getState().incident.ptsIncidentId;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to review

  if (!client) return;

  try {
    const service = client.service('rms-action-report');
    service.timeout = 20000;
    const response = await service.create({
      ptsIncidentID: ptsIncidentId,
      ptsAddressID: afterActionDetailsForm?.values?.ptsAddressId || null,

      Ambushed: afterActionDetailsForm?.values?.ambushed || null,
      BeginDatetime: formatSaveDate(afterActionDetailsForm?.values?.beginDatetime) || null,
      Condition: afterActionDetailsForm?.values?.condition || null,
      InvolvedMultiAgencies: afterActionDetailsForm?.values?.involvedMultiAgencies || null,
      OfficerKilledOrAssaulted: afterActionDetailsForm?.values?.officerKilledOrAssaulted || null,
      OfficerApproachedOffender: afterActionDetailsForm?.values?.officerApproachedOffender || null,
      InvestigatedByAnotherAgency:
        afterActionDetailsForm?.values?.investigatedByAnotherAgency || null,
      ReasonOfInitialContact: afterActionDetailsForm?.values?.reasonOfInitialContact || null,
      SeniorOfficerPresent: afterActionDetailsForm?.values?.seniorOfficerPresent || null,
      Notes: afterActionDetailsForm?.values?.notes || null,
      TotalOfficers: afterActionDetailsForm?.values?.totalOfficers || null,
      TotalAgencyOfficers: afterActionDetailsForm?.values?.totalAgencyOfficers || null,
      TotalOffenders: afterActionDetailsForm?.values?.totalOffenders || null,

      CreatedBy: user,
      UpdatedBy: user,

      Status: afterActionDetailsForm?.values?.status,
      ClearanceLevel: afterActionDetailsForm?.values?.actionClearanceLevel,
      OfficerCompletingReport: afterActionDetailsForm?.values?.officerCompletingReport || null,
      OfficerCompletingReportFullname:
        afterActionDetailsForm?.values?.officerCompletingReportFullname || '',
      ReportDatetime: formatSaveDate(afterActionDetailsForm?.values?.reportDatetime) || null,
      InvolveMultiAgenciesComments:
        afterActionDetailsForm?.values?.involveMultiAgenciesComments || null,
      InvestigatedByAnotherAgencyComments:
        afterActionDetailsForm?.values?.investigatedByAnotherAgencyComments || null,
    });

    const result = await service.get(response.ptsIncAfterActionID);

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateAfterActionDetails = async (afterActionDetailsForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to review

  if (!client) return;

  try {
    const service = client.service('rms-action-report');
    service.timeout = 20000;
    const result = await service.patch(afterActionDetailsForm?.ptsIncAfterActionId, {
      ptsIncidentID: afterActionDetailsForm?.values?.ptsIncidentId || null,
      ptsAddressID: afterActionDetailsForm?.values?.ptsAddressId || null,
      Ambushed: afterActionDetailsForm?.values?.ambushed || null,
      BeginDatetime: formatSaveDate(afterActionDetailsForm?.values?.beginDatetime) || null,
      Condition: afterActionDetailsForm?.values?.condition || null,
      InvolvedMultiAgencies: afterActionDetailsForm?.values?.involvedMultiAgencies || null,
      OfficerKilledOrAssaulted: afterActionDetailsForm?.values?.officerKilledOrAssaulted || null,
      OfficerApproachedOffender: afterActionDetailsForm?.values?.officerApproachedOffender || null,
      InvestigatedByAnotherAgency:
        afterActionDetailsForm?.values?.investigatedByAnotherAgency || null,
      ReasonOfInitialContact: afterActionDetailsForm?.values?.reasonOfInitialContact || null,
      SeniorOfficerPresent: afterActionDetailsForm?.values?.seniorOfficerPresent || null,
      Notes: afterActionDetailsForm?.values?.notes || null,
      TotalOfficers: afterActionDetailsForm?.values?.totalOfficers || null,
      TotalAgencyOfficers: afterActionDetailsForm?.values?.totalAgencyOfficers || null,
      TotalOffenders: afterActionDetailsForm?.values?.totalOffenders || null,
      UpdatedBy: user,

      Status: afterActionDetailsForm?.values?.status,
      ClearanceLevel: afterActionDetailsForm?.values?.actionClearanceLevel,
      OfficerCompletingReport: afterActionDetailsForm?.values?.officerCompletingReport || null,
      OfficerCompletingReportFullname:
        afterActionDetailsForm?.values?.officerCompletingReportFullname || '',
      ReportDatetime: formatSaveDate(afterActionDetailsForm?.values?.reportDatetime) || null,
      InvolveMultiAgenciesComments:
        afterActionDetailsForm?.values?.involveMultiAgenciesComments || null,
      InvestigatedByAnotherAgencyComments:
        afterActionDetailsForm?.values?.investigatedByAnotherAgencyComments || null,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createAfterActionReview = async (afterActionReviewForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const ptsIncidentId = store.store.getState().incident.ptsIncidentId;
  const ptsIncAfterActionId = store.store.getState().incident.selectedActionReport
    .ptsIncAfterActionId;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to review

  if (!client) return;

  try {
    const service = client.service('rms-action-report-review');
    service.timeout = 20000;
    const response = await service.create({
      ptsIncAfterActionID: ptsIncAfterActionId,
      ptsIncidentID: ptsIncidentId,

      ReviewType: afterActionReviewForm?.values?.reviewType || null,
      ReviewDateTime: afterActionReviewForm?.values?.reviewDateTime || null,
      IsFinalReview: afterActionReviewForm?.values?.isFinalReview || null,
      ReviewedBy: afterActionReviewForm?.values?.reviewedBy || null,
      ReviewerFullName: afterActionReviewForm?.values?.reviewerFullName || null,
      ReviewFinding: afterActionReviewForm?.values?.reviewFinding || null,
      RecommendedAction: afterActionReviewForm?.values?.recommendedAction || null,
      ReviewComments: afterActionReviewForm?.values?.reviewComments || null,
      CreatedBy: user,
      UpdatedBy: user,
    });

    const result = await service.get(response.ptsIncAfterActionReviewID);

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateAfterActionReview = async (afterActionReviewForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to review

  if (!client) return;

  try {
    const service = client.service('rms-action-report-review');
    service.timeout = 20000;
    const result = await service.patch(afterActionReviewForm?.ptsIncAfterActionReviewId, {
      ReviewType: afterActionReviewForm?.values?.reviewType || null,
      ReviewDateTime: afterActionReviewForm?.values?.reviewDateTime || null,
      IsFinalReview: afterActionReviewForm?.values?.isFinalReview || null,
      ReviewedBy: afterActionReviewForm?.values?.reviewedBy || null,
      ReviewerFullName: afterActionReviewForm?.values?.reviewerFullName || null,
      ReviewFinding: afterActionReviewForm?.values?.reviewFinding || null,
      RecommendedAction: afterActionReviewForm?.values?.recommendedAction || null,
      ReviewComments: afterActionReviewForm?.values?.reviewComments || null,
      UpdatedBy: user,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const removeAfterActionReviewFromStore = (ptsIncAfterActionReviewId) => {
  return async (dispatch) => {
    let { selectedActionReport, actionReport } = store.store.getState().incident;

    const updatedPartiesList = selectedActionReport.actionReportReviews.filter(
      (review) => review.ptsIncAfterActionReviewId != ptsIncAfterActionReviewId
    );

    selectedActionReport.actionReportReviews = updatedPartiesList;

    dispatch(setSelectedActionReport(selectedActionReport));

    const updatedActionReportsList = actionReport.map((ar) => {
      if (ar.ptsIncAfterActionId == selectedActionReport.ptsIncAfterActionId)
        return selectedActionReport;
      else return ar;
    });

    dispatch({
      type: GET_AFTER_ACTION_REPORT,
      payload: updatedActionReportsList,
    });
  };
};

export const removeAfterActionReviewFromDatabase = async (ptsIncAfterActionReviewId) => {
  const wsClient = store.store.getState().websocket.websocket;

  if (!wsClient) return;

  const service = wsClient.service('rms-action-report-review');
  service.timeout = 2000000;

  const removedReview = await service.remove(ptsIncAfterActionReviewId);
};

export const createAfterActionParty = async (afterActionPartyForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const ptsIncidentId = store.store.getState().incident.ptsIncidentId;
  const ptsIncAfterActionId = store.store.getState().incident.selectedActionReport
    .ptsIncAfterActionId;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to Party

  if (!client) return;

  try {
    const service = client.service('rms-action-report-party');
    service.timeout = 20000;

    const getFullName = () => {
      let FullName = '';

      FullName = afterActionPartyForm?.values?.firstName
        ? afterActionPartyForm?.values?.firstName + ' '
        : '';
      FullName += afterActionPartyForm?.values?.middleName
        ? afterActionPartyForm?.values?.middleName + ' '
        : '';
      FullName += afterActionPartyForm?.values?.lastName
        ? afterActionPartyForm?.values?.lastName + ' '
        : '';

      return FullName.trim();
    };

    const response = await service.create({
      ptsIncAfterActionID: ptsIncAfterActionId,
      ptsIncidentID: ptsIncidentId || null,
      ptsPersonID: afterActionPartyForm?.values?.ptsPersonId || null,
      PartyType: afterActionPartyForm?.values?.partyType || null,

      FirstName: afterActionPartyForm?.values?.firstName || null,
      LastName: afterActionPartyForm?.values?.lastName || null,
      MiddleName: afterActionPartyForm?.values?.middleName || null,
      FullName: getFullName() || null,
      Age: afterActionPartyForm?.values?.age || null,
      Height: afterActionPartyForm?.values?.height || null,
      Weight: afterActionPartyForm?.values?.weight || null,
      Race: afterActionPartyForm?.values?.race || null,
      Sex: afterActionPartyForm?.values?.sex || null,
      Ethnicity: afterActionPartyForm?.values?.ethnicity || null,

      OfficerYearOfService: afterActionPartyForm?.values?.officerYearOfService || null,
      IsOfficerFullTime: afterActionPartyForm?.values?.isOfficerFullTime || null,
      OfficerOnDuty: afterActionPartyForm?.values?.officerOnDuty || null,
      OfficerReadilyIdentifiable: afterActionPartyForm?.values?.officerReadilyIdentifiable || null,

      OfficerInjured: afterActionPartyForm?.values?.officerInjured || null,
      OfficerAssignmentType: afterActionPartyForm?.values?.officerAssignmentType || null,
      DidOfficerDischargeFirearm: afterActionPartyForm?.values?.didOfficerDischargeFirearm || null,
      NumberOfShotsFired: afterActionPartyForm?.values?.numberOfShotsFired || null,
      LowerDistanceFromOffender: afterActionPartyForm?.values?.lowerDistanceFromOffender || null,
      HigherDistanceFromOffender: afterActionPartyForm?.values?.higherDistanceFromOffender || null,
      OfficerWeapons: afterActionPartyForm?.values?.officerWeapons || null,

      Injuries: afterActionPartyForm?.values?.injuries || null,

      OffenderImpaired: afterActionPartyForm?.values?.offenderImpaired || null,
      OffenderImpairments: afterActionPartyForm?.values?.offenderImpairments || null,
      DidOffenderResist: afterActionPartyForm?.values?.didOffenderResist || null,
      OffenderResistanceUsed: afterActionPartyForm?.values?.offenderResistanceUsed || null,
      OffenderThreatToward: afterActionPartyForm?.values?.offenderThreatToward || null,
      OffenderArmed: afterActionPartyForm?.values?.offenderArmed || null,
      ForceTypesToOffender: afterActionPartyForm?.values?.forceTypesToOffender || null,

      CreatedBy: user || null,
      UpdatedBy: user || null,
    });

    const result = await service.get(response.ptsIncAfterActionPartyID);

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateAfterActionParty = async (afterActionPartyForm) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  // const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber(); // need to Party

  if (!client) return;

  const getFullName = () => {
    let FullName = '';

    FullName = afterActionPartyForm?.values?.firstName
      ? afterActionPartyForm?.values?.firstName + ' '
      : '';
    FullName += afterActionPartyForm?.values?.middleName
      ? afterActionPartyForm?.values?.middleName + ' '
      : '';
    FullName += afterActionPartyForm?.values?.lastName
      ? afterActionPartyForm?.values?.lastName + ' '
      : '';

    return FullName.trim();
  };

  try {
    const service = client.service('rms-action-report-party');
    service.timeout = 20000;
    const result = await service.patch(afterActionPartyForm?.ptsIncAfterActionPartyId, {
      ptsPersonID: afterActionPartyForm?.values?.ptsPersonId || null,
      PartyType: afterActionPartyForm?.values?.partyType || null,

      FirstName: afterActionPartyForm?.values?.firstName || null,
      LastName: afterActionPartyForm?.values?.lastName || null,
      MiddleName: afterActionPartyForm?.values?.middleName || null,
      FullName: getFullName() || null,
      Age: afterActionPartyForm?.values?.age || null,
      Height: afterActionPartyForm?.values?.height || null,
      Weight: afterActionPartyForm?.values?.weight || null,
      Race: afterActionPartyForm?.values?.race || null,
      Sex: afterActionPartyForm?.values?.sex || null,
      Ethnicity: afterActionPartyForm?.values?.ethnicity || null,

      OfficerYearOfService: afterActionPartyForm?.values?.officerYearOfService || null,
      IsOfficerFullTime: afterActionPartyForm?.values?.isOfficerFullTime || null,
      OfficerOnDuty: afterActionPartyForm?.values?.officerOnDuty || null,
      OfficerReadilyIdentifiable: afterActionPartyForm?.values?.officerReadilyIdentifiable || null,

      OfficerInjured: afterActionPartyForm?.values?.officerInjured || null,
      OfficerAssignmentType: afterActionPartyForm?.values?.officerAssignmentType || null,
      DidOfficerDischargeFirearm: afterActionPartyForm?.values?.didOfficerDischargeFirearm || null,
      NumberOfShotsFired: afterActionPartyForm?.values?.numberOfShotsFired || null,
      LowerDistanceFromOffender: afterActionPartyForm?.values?.lowerDistanceFromOffender || null,
      HigherDistanceFromOffender: afterActionPartyForm?.values?.higherDistanceFromOffender || null,
      OfficerWeapons: afterActionPartyForm?.values?.officerWeapons || null,

      Injuries: afterActionPartyForm?.values?.injuries || null,

      OffenderImpaired: afterActionPartyForm?.values?.offenderImpaired || null,
      OffenderImpairments: afterActionPartyForm?.values?.offenderImpairments || null,
      DidOffenderResist: afterActionPartyForm?.values?.didOffenderResist || null,
      OffenderResistanceUsed: afterActionPartyForm?.values?.offenderResistanceUsed || null,
      OffenderThreatToward: afterActionPartyForm?.values?.offenderThreatToward || null,
      OffenderArmed: afterActionPartyForm?.values?.offenderArmed || null,
      ForceTypesToOffender: afterActionPartyForm?.values?.forceTypesToOffender || null,

      UpdatedBy: user || null,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const removeAfterActionPartyFromStore = (ptsIncAfterActionPartyId) => {
  return async (dispatch) => {
    let { selectedActionReport, actionReport } = store.store.getState().incident;

    const updatedPartiesList = selectedActionReport.actionReportParties.filter(
      (party) => party.ptsIncAfterActionPartyId != ptsIncAfterActionPartyId
    );

    selectedActionReport.actionReportParties = updatedPartiesList;

    dispatch(setSelectedActionReport(selectedActionReport));

    const updatedActionReportsList = actionReport.map((ar) => {
      if (ar.ptsIncAfterActionId == selectedActionReport.ptsIncAfterActionId)
        return selectedActionReport;
      else return ar;
    });

    dispatch({
      type: GET_AFTER_ACTION_REPORT,
      payload: updatedActionReportsList,
    });
  };
};

export const removeAfterActionPartyFromDatabase = async (ptsIncAfterActionPartyId) => {
  const wsClient = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!wsClient) return;

  const service = wsClient.service('rms-action-report-party');
  service.timeout = 2000000;

  const removedParty = await service.remove(ptsIncAfterActionPartyId);
};

// After Action Section End

export const updateNarratives = async (form, dispatch) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-narratives');
    service.timeout = 20000;
    const result = await service.patch(form.values.narrativeId, {
      NarrativeTitle: form.values.narrativeTitle,
      NarrativeSequence: Number(form.values.sequence),
      NarrativeBody: form.values.narrativeBody,
      NarrativeType: form.values.narrativeType,
      ApprovedDT: form.values.approvedDT,
      NarrativeDT: form.values.narrativeDT,
      ApprovedBy: form.values.approvedBy,
      ptsEntryClerkID: form.values.entryClerkId,
      EntryClerk: form.values.entryClerk,
      ptsAuthorID: form.values.authorId,
      ClearanceLevel: form.values.narrativeClearanceLevel || '0',
      attachments: form.values.attachments,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log('error', error);
    dispatch(notify(error.message, 'error'));
  }
};

export const updateEvidenceCurrentLocation = async (ptsEvidenceId, currentLocation) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('incevidence');
    service.timeout = 20000;

    if (ptsEvidenceId) {
      const res = await service.patch(ptsEvidenceId, {
        CurrentLocation: currentLocation,
        UpdatedBy: user,
      });

      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateEvidenceItem = async (form, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const AgencyID = store.store.getState().user.userAgency;

  const EvidenceID = form.evidenceId ? form.evidenceId : await createCustomEvidenceNumber();

  if (!client) return;
  try {
    const service = client.service('rms-evidence');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceId, {
      ptsIncidentID: incidentID,
      EvidenceID: EvidenceID,
      DaysInEvidence: 0,
      IncidentID: form.values.reportNumber,
      Category: form.values.category,
      EvidenceType: form.values.evidenceType,
      Description: form.values.description,
      LocationCollected: form.values.locationCollected,
      CollectedBy: form.values.collectedBy,
      DateTimeCollected: form.values.collectedDate,
      RequiredAction: form.values.requiredAction,
      ActionDate: form.values.actionDate,
      TemporaryLocation: form.values.temporaryLocation,
      Notes: form.values.notes,
      SubmittedBy: form.values.submittedBy,
      IsIncludeOnReport: false,
      DisposalCode: form.values.disposalCode,
      Quantity: form.values.quantity,
      TagNumber: form.values.tagNumber,
      Make: form.values.make,
      Model: form.values.model,
      VIN: form.values.vin,
      LicenseNumber: form.values.licenseNumber,
      SerialNumber: form.values.serialNumber,
      Condition: form.values.condition,
      SubstanceType: form.values.substanceType,
      SubstanceClass: form.values.substanceClass,
      Measurement: form.values.measurement,
      ClearanceLevel: form.values.evidenceClearanceLevel || '0',
      attachments: form.values.attachments,
      UpdatedBy: user,
      SubmittedAt: form.values.submittedAt,
      AgencyID: AgencyID,
      Offenses: form.values?.offenses ? JSON.stringify(form.values?.offenses) : null,
      PropertyOwner: form.values?.propertyOwner || '',
      PropertyOwnerType: form.values?.propertyOwnerType || '',
      CurrentLocation: form.values?.currentLocation || '',
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceCustody = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user;

  const currentUserFullname = formatPersonFullName(user);

  if (!client) return;
  try {
    const service = client.service('rms-evidence-custody');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceCustodyId, {
      ptsIncidentID: incidentID,
      Status: form.values.Status,
      CustodyFrom: form.values.custodyFrom,
      CustodyTo: form.values.custodyTo,
      Reason: form.values.reason,
      Notes: form.values.notes,
      RequiredAction: form.values.requiredAction,
      DisposalCode: form.values.disposalCode,
      TransactionDate: form.values.transactionDate,
      TransactionType: form.values.transactionType,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      UpdatedBy: currentUserFullname,
      ComeFromCheckInFlow: form.values.comeFromCheckInFlow || false,
    });

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceDisposition = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-disposition');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceDispositionId, {
      ptsIncidentID: incidentID,
      BypassDispAssumption: form.values.bypassDisposalAssumption,
      ReadyForDisposal: form.values.readyForDisposal,
      FinalDisposition: form.values.finalDisposition,
      DispositionDate: form.values.dispositionDate || null,
      DispositionNotes: form.values.dispositionNotes,
      DisposedOfBy: form.values.disposedOfBy,

      ReturnBy: form.values.returnBy?.ptsPersonID,
      ReturnDate: form.values.returnDate ? formatSaveDate(form.values.returnDate) : null,
      ReturnTo: form.values.returnTo?.ptsPersonID,
      HowDestroyed: form.values.howDestroyed?.Code || null,
      DestructionDate: form.values.DestructionDate
        ? formatSaveDate(form.values.DestructionDate)
        : null,
      DestructionReturn: form.values.DestructionReturn,
      DestructionReturnDate: form.values.DestructionReturnDate
        ? formatSaveDate(form.values.DestructionReturnDate)
        : null,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      IsDisposed: form.values.isDisposed,
      UpdatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceStorage = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-storage');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceStorageId, {
      // EvidenceID: '', // TODO: Agency defined custom evidence ID to be implemented
      ptsIncidentID: incidentID,
      DateTimeIn: form.values.dateIn,
      StorageLocation: form.values.storageLocation,
      SubLocation: form.values.subLocation,
      Container: form.values.container,
      UpdatedBy: form.values.enteredBy,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
    });

    return {
      ptsEvidenceId: result?.ptsEvidenceID,
      ptsIncidentId: result?.ptsIncidentID,
      ptsEvidenceStorageId: result?.ptsEvidenceStorageID,
      values: {
        incidentId: result?.ptsIncidentID,
        evidenceId: result?.ptsEvidenceID,
        evidenceStorageId: result?.ptsEvidenceStorageID,
        dateIn: result?.DateTimeIn,
        storageLocation: result?.StorageLocation,
        container: result?.Container,
        subLocation: result?.SubLocation,
        enteredBy: result?.UpdatedBy,
        created: result?.Created,
        createdBy: result?.CreatedBy,
        updated: result?.Updated,
        updatedBy: result?.UpdatedBy,
      },
    };
  } catch (e) {
    console.log(e);
  }
};

export const updateOffense = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offenses');
    service.timeout = 20000;
    const result = await service.patch(form.ptsOffenseId, {
      CriminalActivity: form.values.criminalActivity,
      IncidentLocationType: form.values.locationCategory,
      BiasMotivations: form.values.biasMotivation,
      OffenderSuspectedOfUsing: form.values.offenderSuspectedOfUsing,
      WeaponForceType: form.values.forceCategory,
      IsGangInvolved: form.values.isGangInvolved,
      IsHomeInvasion: false,
      IsDomesticViolence: false,
      IsIdentityTheft: false,
      IsCargoTheft: form.values.cargoTheft || false,
      IsExcluded: form.values.excludeOffense || false,
      StructuresEntered:
        !isNaN(form.values.structuresEntered) &&
        form.values.structuresEntered !== '' &&
        form.values.structuresEntered !== null
          ? Number(form.values.structuresEntered)
          : null,
      StructuresOccupied: form.values.structuresOccupied || false,
      MethodOfEntry: form.values.methodOfEntry,
      statute: form.values.statuteDetails.Description,
      ptsStatuteID: form.values.statuteDetails.ptsStatuteID,
      ptsFBICode: form.values.statuteDetails.ptsFBICodeID,
      IsAttempted: form.values.offenseAttempted || false,
      ClearanceLevel: form.values.offenseClearanceLevel || '0',
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewSubject = async (form, person, incidentID) => {
  const savedParty = person.personDetail;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-offenders');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      DNACollection: null,
      SubjectMO: null,
      SubjectWeapon: null,
      SubjectStatus: null,
      SubjectInvolvement: null,
      IsArmed: false,
      IsExtradition: false,
      IsUnknown: false,
      Notes: form.values.notes,
      Age: savedParty.Age,
      AgeRangeIncrement: savedParty.AgeRangeIncrement,
      Race: savedParty.Race,
      Sex: savedParty.Sex,
      Ethnicity: savedParty.Ethnicity,
      FirstName: savedParty.FirstName,
      MiddleName: savedParty.MiddleName,
      LastName: savedParty.LastName,
      Prefix: savedParty.Prefix,
      Suffix: savedParty.Suffix,
      NickName: savedParty.NickName,
      FullName: savedParty.FullName,
      EyeColor: savedParty.EyeColor,
      HairColor: savedParty.HairColor,
      HairLength: savedParty.HairLength,
      HairStyle: savedParty.HairStyle,
      Handed: savedParty.Handed,
      BloodType: savedParty.BloodType,
      Height: savedParty.Height,
      Weight: savedParty.Weight,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsOffenseID: form.ptsOffenseId,
      Born: savedParty.Born,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSubject = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-offenders');
    service.timeout = 20000;
    const result = await service.patch(form.ptsSubjectId, {
      DNACollection: null,
      SubjectMO: null,
      SubjectWeapon: null,
      SubjectStatus: null,
      SubjectInvolvement: null,
      IsArmed: false,
      IsExtradition: false,
      IsUnknown: false,
      Notes: form.values.notes,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewVictim = async (form, person, incidentID) => {
  const savedParty = person.personDetail || person.addressDetails;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-victims');
    service.timeout = 20000;
    const result = await service.create({
      ptsParentID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ptsIncPlaceID: person.addressDetails?.values?.ptsIncPlaceId,
      ptsOffenseID: form.ptsOffenseId,
      ParentType: person.ptsIncPersonId ? 'Person' : 'Place',
      VictimCategory: form.values.category,
      VICAPCaseNumber: form.values.caseNumber,
      VictimDisposition: form.values.disposition,
      VictimTreatment: form.values.treatment,
      ResidentStatus: form.values.residentStatus,
      IsSeekingPersecution: form.values.seekingProsecution || false,
      IsSociety: form.values.society || false,
      AggAssaultType: form.values.aggravatedAssaultHomicide,
      InjuryType: form.values.injuryType,
      NegligentManslaughter: form.values.negligentManslaughter,
      JustifiableHomicide: form.values.justifiableHomicide,
      AddlJustifiableHomicide: form.values.additionalJustifiableHomicide,
      OfficerActivity: form.values.officerActivity,
      OfficerAssignment: form.values.officerAssignment,
      OfficerORI: form.values.officerORI,
      Age: savedParty.values?.age,
      AgeRangeIncrement: savedParty.values?.ageRangeIncrement,
      Race: savedParty.values?.race,
      Sex: savedParty.values?.sex,
      Ethnicity: savedParty.values?.ethnicity,
      FirstName: savedParty.values?.firstName,
      MiddleName: savedParty.values?.middleName,
      LastName: savedParty.values?.lastName,
      Prefix: savedParty.values?.prefix,
      Suffix: savedParty.values?.suffix,
      NickName: savedParty.values?.nickName,
      FullName: `${savedParty.values?.firstName || ''} ${savedParty.values?.middleName || ''} ${
        savedParty.values?.lastName || ''
      }`,
      EyeColor: savedParty.values?.eyeColor,
      HairColor: savedParty.values?.hairColor,
      HairLength: savedParty.values?.hairLength,
      HairStyle: savedParty.values?.hairStyle,
      Handed: savedParty.values?.handed,
      BloodType: savedParty.values?.bloodType,
      Height: savedParty.values?.height,
      Weight: savedParty.values?.weight,
      PlaceName: savedParty.values?.placeName,
      PlaceType: savedParty.values?.placeType,
      Notes: form.values.notes,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      Born: savedParty.values?.birthdate,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateVictim = async (form, victims) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;

  /* Updating all the victims data of same person */
  victims.forEach(async (v) => {
    try {
      const service = client.service('rms-offense-victims');
      service.timeout = 20000;
      const result = await service.patch(v.incVictimDetails.ptsVictimId, {
        VictimCategory: form.values.category,
        VICAPCaseNumber: form.values.caseNumber,
        VictimDisposition: form.values.disposition,
        VictimTreatment: form.values.treatment,
        ResidentStatus: form.values.residentStatus,
        IsSeekingPersecution: form.values.seekingProsecution || false,
        IsSociety: form.values.society || false,
        AggAssaultType: form.values.aggravatedAssaultHomicide,
        InjuryType: form.values.injuryType,
        NegligentManslaughter: form.values.negligentManslaughter,
        JustifiableHomicide: form.values.justifiableHomicide,
        AddlJustifiableHomicide: form.values.additionalJustifiableHomicide,
        OfficerActivity: form.values.officerActivity,
        OfficerAssignment: form.values.officerAssignment,
        OfficerORI: form.values.officerORI,
        Notes: form.values.notes,
        UpdatedBy: user,
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  });
};

export const addPartiesAvailableOffenses = async (form, dispatch) => {
  try {
    const client = store.store.getState().websocket.websocket;
    const parties = store.store.getState().incident.parties;
    const party = parties.find((party) => party.ptsIncPersonId === form.ptsIncPersonId);

    const currAvailableOffense = form.ptsOffenseId;
    const prevAvailableOffenses = party.personDetail.values.availableOffenses;

    let updatedAvailableOffenses = [];
    if (prevAvailableOffenses !== null) {
      const hasOffenseID = prevAvailableOffenses.find((item) => item === currAvailableOffense);

      if (!hasOffenseID) {
        updatedAvailableOffenses = prevAvailableOffenses;
        updatedAvailableOffenses.push(currAvailableOffense);

        updatedAvailableOffenses.sort();
      } else {
        updatedAvailableOffenses = prevAvailableOffenses;
      }
    } else {
      updatedAvailableOffenses.push(currAvailableOffense);
    }

    updatedAvailableOffenses = JSON.stringify(updatedAvailableOffenses);

    const service = client.service('incincidentpartyperson');
    service.timeout = 20000;

    const response = await service.patch(form.ptsIncPersonId, {
      AvailableOffenses: updatedAvailableOffenses,
    });

    if (response) {
      const availableOffenses = JSON.parse(response.AvailableOffenses);

      parties.map((party) => {
        if (party.ptsIncPersonId === response.ptsIncPersonID) {
          party.personDetail.values.availableOffenses = availableOffenses;
        }
      });

      return dispatch({
        type: ADD_PARTIES,
        payload: parties,
      });
    }
  } catch (error) {
    console.log('error = ', error);
  }
};

export const removePartiesAvailableOffenses = async (form, dispatch) => {
  try {
    const client = store.store.getState().websocket.websocket;
    const parties = store.store.getState().incident.parties;
    const party = parties.find((party) => party.ptsIncPersonId === form.ptsIncPersonId);

    const currAvailableOffense = form.ptsOffenseId;
    const prevAvailableOffenses = party.personDetail.values.availableOffenses;

    let updatedAvailableOffenses = prevAvailableOffenses.filter(
      (item) => item !== currAvailableOffense
    );

    if (updatedAvailableOffenses.length === 0) updatedAvailableOffenses = null;

    if (updatedAvailableOffenses)
      updatedAvailableOffenses = JSON.stringify(updatedAvailableOffenses);

    const service = client.service('incincidentpartyperson');
    service.timeout = 20000;

    const response = await service.patch(form.ptsIncPersonId, {
      AvailableOffenses: updatedAvailableOffenses,
    });

    if (response) {
      let availableOffenses = null;
      if (response.AvailableOffenses) availableOffenses = JSON.parse(response.AvailableOffenses);

      parties.map((party) => {
        if (party.ptsIncPersonId === response.ptsIncPersonID) {
          party.personDetail.values.availableOffenses = availableOffenses;
        }
      });

      return dispatch({
        type: ADD_PARTIES,
        payload: parties,
      });
    }
  } catch (error) {
    console.log('error = ', error);
  }
};

export const addNewArrestee = async (form, person, incidentID, dispatch) => {
  const savedParty = person.personDetail;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-arrestees');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ArrestLocation: form.values.arrestLocation || null,
      ArrestDate: form.values.arrestDate ? formatSaveDate(form.values.arrestDate) : null,
      ArrestType: form.values.arrestType,
      IsCleared: form.values.cleared || false,
      ArresteeArmed: form.values.arresteeArmedWith,
      JuvenileDisposition: form.values.juvenileDisposition,
      ArrestSegmentIndicator: form.values.arresteeSegmentsIndicator,
      ResidentStatus: form.values.arresteeResidentStatus,
      OffenseDescription: form.values.offenseDescription,
      Notes: form.values.notes,
      Age: savedParty.Age,
      AgeRangeIncrement: savedParty.AgeRangeIncrement,
      Race: savedParty.Race,
      Sex: savedParty.Sex,
      Ethnicity: savedParty.Ethnicity,
      FirstName: savedParty.FirstName,
      MiddleName: savedParty.MiddleName,
      LastName: savedParty.LastName,
      Prefix: savedParty.Prefix,
      Suffix: savedParty.Suffix,
      NickName: savedParty.NickName,
      FullName: savedParty.FullName,
      EyeColor: savedParty.EyeColor,
      HairColor: savedParty.HairColor,
      HairLength: savedParty.HairLength,
      HairStyle: savedParty.HairStyle,
      Handed: savedParty.Handed,
      BloodType: savedParty.BloodType,
      Height: savedParty.Height,
      Weight: savedParty.Weight,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsOffenseID: form.ptsOffenseId,
      ptsIncidentID: incidentID,
      ArrestOfficer: form.values.arrestOfficer,
      RightsOfficer: form.values.rightsOfficer,
      RightsLocation: form.values.rightsLocation,
      RightsDate: form.values.rightsDate ? formatSaveDate(form.values.rightsDate) : null,
      CreatedBy: user,
    });

    addPartiesAvailableOffenses(form, dispatch);

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateArrestee = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-arrestees');
    service.timeout = 20000;

    const result = await service.patch(form.ptsArresteeId, {
      ArrestLocation: form.values.arrestLocation || null,
      ArrestDate: form.values.arrestDate ? formatSaveDate(form.values.arrestDate) : null,
      ArrestType: form.values.arrestType,
      IsCleared: form.values.cleared || false,
      ArresteeArmed: form.values.arresteeArmedWith,
      JuvenileDisposition: form.values.juvenileDisposition,
      ArrestSegmentIndicator: form.values.arresteeSegmentsIndicator,
      ResidentStatus: form.values.arresteeResidentStatus,
      Notes: form.values.notes,
      OffenseDescription: form.values.offenseDescription,
      ArrestOfficer: form.values.arrestOfficer,
      RightsOfficer: form.values.rightsOfficer,
      RightsLocation: form.values.rightsLocation,
      RightsDate: form.values.rightsDate ? formatSaveDate(form.values.rightsDate) : null,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const doesRelationshipExist = async (victim, subject, offenseID, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    const relationship = await service.find({
      query: {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        ptsOffenseID: 0,
        ptsIncidentID: incidentID,
        IsDeleted: 0,
        $limit: 1,
      },
    });
    return relationship;
  } catch (error) {
    console.log(error);
  }
};

export const addNewRelationship = async (victim, subject, form, dispatch) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const incRelations = store.store.getState().incident.incRelations;

  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    let relationship = await service.create({
      ptsVictimID: victim.incVictimDetails.ptsVictimId,
      ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
      // ptsOffenseID: form.ptsOffenseId,
      ptsOffenseID: 0,
      ptsIncidentID: form.ptsIncidentId,
      Relationship: form.values.relationship.relationship,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });

    incRelations.push(relationship);

    dispatch(setIncRelationsData(incRelations));

    return relationship;
  } catch (error) {
    console.log(error);
  }
};

export const removeSubjectRelationships = (ptsSubjectID, ptsOffenseID, relations, dispatch) => {
  relations
    .filter((relation) => relation.ptsSubjectID === ptsSubjectID)
    .forEach((relation) =>
      removeRelationship({
        ptsVictimID: relation.ptsVictimID,
        ptsSubjectID,
        ptsOffenseID: 0,
        ptsIncidentID: relation.ptsIncidentID,
      })
    );

  dispatch(setIncRelationsData(relations));
};

export const removeVictimRelationships = (ptsVictimID, ptsOffenseID, relations, dispatch) => {
  relations
    .filter((relation) => relation.ptsVictimID === ptsVictimID)
    .forEach((relation) =>
      removeRelationship({
        ptsVictimID,
        ptsSubjectID: relation.ptsSubjectID,
        ptsOffenseID: 0,
        ptsIncidentID: relation.ptsIncidentID,
      })
    );

  dispatch(setIncRelationsData(relations));
};

export const removeRelationship = (key) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    service.update(key, {
      IsDeleted: true,
      UpdatedBy: user,
    });
    console.log('%c relationship deleted: ', 'color: red', 'key: ', key);
  } catch (error) {
    console.log(error);
  }
};

export const updateRelationship = async (victim, subject, form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    let relationship = await service.update(
      {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        // ptsOffenseID: form.ptsOffenseId,
        ptsOffenseID: 0,
        ptsIncidentID: form.ptsIncidentId,
      },
      {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        // ptsOffenseID: form.ptsOffenseId,
        ptsOffenseID: 0,
        ptsIncidentID: form.ptsIncidentId,
        Relationship: form.values.relationship.relationship,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
      }
    );
    return relationship[1];
  } catch (error) {
    console.log(error);
  }
};

export const addNewDVVictim = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const incVictims = store.store.getState().incident.incVictims;

  const victimId = incVictims.find(
    (victim) =>
      victim.incVictimDetails.ptsIncPersonId === form.ptsIncPersonId &&
      victim.incVictimDetails.ptsOffenseID === form.ptsOffenseId
  )?.incVictimDetails.ptsVictimId;
  if (!client) return;
  try {
    const service = client.service('rms-offense-dvvictim');
    service.timeout = 20000;
    let dvVictim = await service.create({
      ...form.values,
      ptsVictimID: form.ptsVictimId || victimId,
      CreatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const updateDVVictim = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvvictim');
    service.timeout = 20000;
    let dvVictim = await service.patch(form.ptsDVVictimId, {
      ...form.values,
      UpdatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const addNewDVAdditional = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvadditional');
    service.timeout = 20000;
    let dvVictim = await service.create({
      ...form.values,
      ptsVictimID: form.ptsVictimId,
      CreatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const updateDVAdditional = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvadditional');
    service.timeout = 20000;
    let dvAdditional = await service.patch(form.ptsDVAdditionalId, {
      ...form.values,
      UpdatedBy: user,
    });
    return dvAdditional;
  } catch (error) {
    console.log(error);
  }
};

export const addNewSuspect = async (form, person) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvsuspect');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ...form.values,
      ptsVictimID: form.ptsVictimId,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSuspect = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvsuspect');
    service.timeout = 20000;
    const result = await service.patch(form.ptsDVSuspectId, {
      ...form.values,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addPropertiesAvailableOffenses = async (form, dispatch) => {
  try {
    const client = store.store.getState().websocket.websocket;
    const properties = store.store.getState().incident.properties;
    const property = properties.find(
      (property) => property.propertyDetails.incIncidentPropertyId === form.incIncidentPropertyId
    );

    const currAvailableOffense = form.ptsOffenseId;
    const prevAvailableOffenses = property.propertyDetails.values.availableOffenses;

    let updatedAvailableOffenses = [];
    if (prevAvailableOffenses !== null) {
      const hasOffenseID = prevAvailableOffenses.find((item) => item === currAvailableOffense);

      if (!hasOffenseID) {
        updatedAvailableOffenses = prevAvailableOffenses;
        updatedAvailableOffenses.push(currAvailableOffense);

        updatedAvailableOffenses.sort();
      } else {
        updatedAvailableOffenses = prevAvailableOffenses;
      }
    } else {
      updatedAvailableOffenses.push(currAvailableOffense);
    }

    updatedAvailableOffenses = JSON.stringify(updatedAvailableOffenses);

    const service = client.service('incincidentproperty');
    service.timeout = 20000;

    const response = await service.patch(form.incIncidentPropertyId, {
      AvailableOffenses: updatedAvailableOffenses,
    });

    if (response) {
      const availableOffenses = JSON.parse(response.AvailableOffenses);

      properties.map((property) => {
        if (property.propertyDetails.incIncidentPropertyId === response.incIncidentPropertyID) {
          property.propertyDetails.values.availableOffenses = availableOffenses;
        }
      });

      return dispatch({
        type: ADD_PROPERTIES,
        payload: properties,
      });
    }
  } catch (error) {
    console.log('error = ', error);
  }
};

export const removePropertiesAvailableOffenses = async (form, dispatch) => {
  try {
    const client = store.store.getState().websocket.websocket;
    const properties = store.store.getState().incident.properties;
    const property = properties.find(
      (property) => property.propertyDetails.incIncidentPropertyId === form.incIncidentPropertyId
    );

    const currAvailableOffense = form.ptsOffenseId;
    const prevAvailableOffenses = property.propertyDetails.values.availableOffenses;

    let updatedAvailableOffenses = null;
    updatedAvailableOffenses = prevAvailableOffenses.filter(
      (item) => item !== currAvailableOffense
    );

    if (updatedAvailableOffenses.length === 0) updatedAvailableOffenses = null;

    if (updatedAvailableOffenses)
      updatedAvailableOffenses = JSON.stringify(updatedAvailableOffenses);

    const service = client.service('incincidentproperty');
    service.timeout = 20000;

    const response = await service.patch(form.incIncidentPropertyId, {
      AvailableOffenses: updatedAvailableOffenses,
    });

    if (response) {
      let availableOffenses = null;
      if (response.AvailableOffenses) availableOffenses = JSON.parse(response.AvailableOffenses);

      properties.map((property) => {
        if (property.propertyDetails.incIncidentPropertyId === response.incIncidentPropertyID) {
          property.propertyDetails.values.availableOffenses = availableOffenses;
        }
      });

      return dispatch({
        type: ADD_PROPERTIES,
        payload: properties,
      });
    }
  } catch (error) {
    console.log('error = ', error);
  }
};

export const addNewProperty = async (form, property, dispatch) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('incproperty');
    service.timeout = 20000;
    const existingProperty = await service.find({
      query: {
        ptsOffenseID: form.ptsOffenseId,
        incIncidentPropertyID: property.propertyDetails.incIncidentPropertyId,
        IsDeleted: false,
      },
    });

    if (existingProperty.total > 0) {
      existingProperty.data.forEach(async (item) => {
        await service.remove(item.ptsPropertyID);
      });
    }
    let prop = await service.create({
      ptsOffenseID: form.ptsOffenseId,
      // PropertyDescription: property.propertyDetails.values.description || null,
      PropertyLossType: form.values.type || null,
      ParentType: property.propertyDetails.values.parentType,
      ptsParentID:
        property.propertyDetails.ptsItemId ||
        property.propertyDetails.ptsFirearmId ||
        property.propertyDetails.ptsJewelryId ||
        property.propertyDetails.ptsStructureId ||
        property.propertyDetails.ptsSubstanceId ||
        property.propertyDetails.ptsVehicleId,
      Value: form.values.value || null,
      Quantity: form.values.quantity || null,
      QuantityMeasure: form.values.quantityMeasure || null,
      RecoveredValue: form.values.recoveredValue || null,
      StolenVehicles: form.values.stolenVehicles || null,
      RecoveredVehicles: form.values.recoveredVehicles || null,
      PropertyDescription: form.values.propertyDescription || null,
      RecoveryDate: form.values.recoveredDate || null,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      PropertyStatus: form.values.status,
      Notes: form.values.notes,
      IsStolenFromOtherJurisdiction: form.values.stolenFromOtherJurisdiction || false,
      IsRecoveredFromOtherJurisdiction: form.values.recoveredFromOtherJurisdiction || false,
      StatusDate: form.values.statusDate || null,
      incIncidentPropertyID: property.propertyDetails.incIncidentPropertyId,
      CreatedBy: user,
    });

    addPropertiesAvailableOffenses(form, dispatch);

    return prop;
  } catch (error) {
    console.log(error);
  }
};

export const updateProperty = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('incproperty');
    service.timeout = 20000;

    const result = await service.patch(form.ptsPropertyId, {
      PropertyLossType: form.values.type,
      Value: form.values.value || null,
      RecoveredValue: form.values.recoveredValue || null,
      RecoveryDate: form.values.recoveredDate,
      PropertyStatus: form.values.status,
      Quantity: form.values.quantity || null,
      QuantityMeasure: form.values.quantityMeasure || null,
      Notes: form.values.notes,
      IsStolenFromOtherJurisdiction: form.values.stolenFromOtherJurisdiction || false,
      IsRecoveredFromOtherJurisdiction: form.values.recoveredFromOtherJurisdiction || false,
      StatusDate: form.values.statusDate,
      UpdatedBy: user,
      StolenVehicles: form.values.stolenVehicles ? parseInt(form.values.stolenVehicles) : 0,
      RecoveredVehicles: form.values.recoveredVehicles
        ? parseInt(form.values.recoveredVehicles)
        : 0,
      // PropertyDescription: form.values.propertyDescription,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const findZoneByAgent = async (form) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('inclocationzone');
    service.timeout = 20000;

    let res = await service.find({
      query: {
        ptsLocationID: form.values.ptsLocationId,
        Zone: form.values.zone,
        IsDeleted: true,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addZone = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;

  let zoneFound = await findZoneByAgent(form);

  if (zoneFound.total !== 0) {
    try {
      const service = client.service('rms-inclocationzone');
      service.timeout = 20000;

      let res = await service.update(
        {
          ptsLocationID: form.values.ptsLocationId,
          Zone: form.values.zone,
          UpdatedBy: user,
        },
        {
          IsDeleted: 0,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const service = client.service('inclocationzone');
      service.timeout = 20000;

      let res = await service.create({
        ptsLocationID: form.values.ptsLocationId,
        Zone: form.values.zone,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        CreatedBy: user,
        UpdatedBy: user,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

export const findDispositionByAgent = async (form) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('incdispositions');
    service.timeout = 20000;

    let res = await service.find({
      query: {
        ptsIncidentID: form.values.ptsIncidentId,
        Disposition: form.values.disposition,
        IsDeleted: true,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addDisposition = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const agency = store.store.getState().user.userAgency;

  if (!client) return;

  let zoneFound = await findDispositionByAgent(form);

  if (zoneFound.total !== 0) {
    try {
      const service = client.service('rms-incdisposition');
      service.timeout = 20000;

      let res = await service.update(
        {
          ptsIncidentID: form.values.ptsIncidentId,
          Disposition: form.values.disposition,
          AgencyID: agency,
        },
        {
          IsDeleted: 0,
          Filed: form.values.filed,
          UpdatedBy: user,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const service = client.service('incdispositions');
      service.timeout = 20000;
      let res = await service.create({
        ptsIncidentID: form.values.ptsIncidentId,
        Disposition: form.values.disposition,
        Filed: form.values.filed,
        AgencyID: agency,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        CreatedBy: user,
        UpdatedBy: user,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

export const addTags = async (form, parentType, ptsParentId) => {
  const client = store.store.getState().websocket.websocket;
  const tagId = form.values.ptsTagId;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;

  if (tagId) {
    try {
      const service = client.service('rms-tags-v2');
      service.timeout = 20000;

      let res = await service.update(
        { ptsTagID: tagId, IsDeleted: 0 },
        {
          ptsTagID: tagId,
          ParentType: parentType,
          ptsParentID: ptsParentId,
          Type: form.values.type,
          Value: form.values.value,
          IsLocked: false,
          IsSealed: false,
          IsDeleted: false,
          UpdatedBy: user,
        }
      );

      return res[1];
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const service = client.service('rms-tags-v2');
      service.timeout = 20000;

      let res = await service.create({
        ParentType: parentType,
        ptsParentID: ptsParentId,
        Type: form.values.type,
        Value: form.values.value,
        UpdatedBy: user,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
      });

      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

export const addValidationErrors = async (validationError) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-validationErrors');
    service.timeout = 20000;

    // console.log(validationError.ErrorIdentifier);

    const errorExists = await service.find({
      query: {
        ErrorIdentifier: validationError.ErrorIdentifier,
        IsDeleted: false,
      },
    });

    // console.log('Is there: ', errorExists);

    if (errorExists.total > 0) {
      const { ptsIBRSID, isErrorAvailable } = errorExists.data[0];

      if (isErrorAvailable) {
        const result = await service.patch(
          null, // Set to null to indicate a multi-patch
          {
            isErrorAvailable: false, // Fields to update
          },
          {
            query: {
              ErrorIdentifier: validationError.ErrorIdentifier, // Condition to match
            },
          }
        );

        return result;
      } else {
        const result = await service.patch(
          null, // Set to null to indicate a multi-patch
          {
            isErrorAvailable: true, // Fields to update
          },
          {
            query: {
              ErrorIdentifier: validationError.ErrorIdentifier, // Condition to match
            },
          }
        );

        return result;
      }
    } else {
      const result = await service.create({
        ...validationError,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
        CreatedBy: user,
        isErrorAvailable: true,
      });

      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeAllErrors = async (offenseId, dispatch) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('incibrs');
    service.timeout = 20000;

    await service.remove(null, { query: { ptsOffenseID: offenseId } });
    dispatch(setNibrsErrors([]));
    dispatch(setValidationErrors([]));
  } catch (error) {
    console.log(error);
  }
};

export const prepareValidationErrors = (validationErrors) => {
  if (!validationErrors) {
    return null;
  }

  let updatedErrors = [];
  validationErrors.forEach((error) => {
    Object.keys(error.errors).forEach((key) => {
      error.errors[key]?.forEach((errorObj) => {
        updatedErrors.push({
          ptsIncidentID: errorObj.ptsIncidentId,
          NIBRSCode: errorObj.errType,
          InputField: errorObj.field,
          Title: errorObj.name,
          Message: errorObj.message,
          PropertyType: errorObj.propertyType || null,
          PartyType: errorObj.partyType || null,
          ptsIncPersonID: errorObj.ptsIncPersonId || null,
          ptsOffenseID: errorObj.ptsOffenseId || null,
          ptsArresteeID: errorObj.ptsArresteeId || null,
          incIncidentPropertyID: errorObj.incIncidentPropertyId || null,
          ptsVictimID: errorObj.victimId || null,
          ptsSubjectID: errorObj.offenderId || null,
          Stepper: errorObj.stepper === 0 ? 0 : errorObj.stepper !== 0 ? errorObj.stepper : null,
          View: errorObj.screen,
          ErrorIdentifier: errorObj.identifier,
        });
      });
    });
  });
  return updatedErrors;
};

// region: validation helpers
export const prepareOffenseErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['offenseErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        offenseErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }

  return validationErrors;
};

export const preparePartiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['partiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        partiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareArresteesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['arresteesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        arresteesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncPropertiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incPropertiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incPropertiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncRelationshipErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incRelationshipErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incRelationshipErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncVictimErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incVictimErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incVictimErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncIncidentErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incIncidentErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incIncidentErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const preparePropertiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['propertiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        propertiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const addIncViewEditHistory = async (action) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user;
  const incident = store.store.getState().incident;

  const dateTime = getDatabaseDateTimeFormat(new Date());

  if (!client) return;
  try {
    const service = client.service('rms-incident-view-edit-history');
    service.timeout = 20000;

    const result = await service.create({
      incidentId: incident.ptsIncidentId,
      userId: user.ptsUserID,
      viewedAt: action === 'view' ? dateTime : null,
      isEdited: action === 'edit' ? 1 : 0,
      isViewed: action === 'view' ? 1 : 0,
      isPrinted: action === 'print' ? 1 : 0,
      printedAt: action === 'print' ? dateTime : null,
      created: dateTime,
      createdBy: user.Username,
      updated: dateTime,
      updatedBy: user.Username,
    });

    return result;
  } catch (err) {
    console.log(err);
  }
};

export const descriptionToCode = (form) => (field, codesArray, key = 'Description') => {
  return codesArray.find((el) => el[key] === form?.values?.[field])?.Code;
};

export const describe = (value, codesArray, key = 'Code', target = 'Description') => {
  return codesArray.find((el) => el[key] === value)?.[target] ?? value;
};

export const updateSameParties = async (data) => {
  let sameParties = [];
  const {
    parties,
    partiesPersonForm,
    ptsIncidentId,
    partiesContactForm,
    partiesEmploymentForm,
    partiesOthersForm,
    offensesMPBasicForm,
    mpRelatedRecords,
  } = data;

  const user = store.store.getState().user.userData?.user.Username;
  const client = store.store.getState().websocket.websocket;

  if (!client) {
    return;
  }

  if (Object.keys(partiesPersonForm.values).length === 0) return;

  if (typeof partiesContactForm.values?.city === 'string') {
    partiesContactForm.values.cityId = getCityIdFromCityName(partiesContactForm.values?.city);
  }

  if (typeof partiesEmploymentForm.values?.city === 'string') {
    partiesEmploymentForm.values.cityId = getCityIdFromCityName(partiesEmploymentForm.values?.city);
  }

  const service = client.service('rms-party');
  service.timeout = 100000;

  parties.forEach((p) => {
    if (
      p.ptsPersonId != 0 &&
      p.ptsPersonId === partiesPersonForm.ptsPersonId &&
      p.ptsIncPersonId !== partiesPersonForm.ptsIncPersonId &&
      !p.personDetail.values.isSociety &&
      !p.personDetail.values.isUnknownParty
    )
      sameParties.push(p);
  });

  if (sameParties.length === 0) return;

  const requestArray = sameParties.map((sp) => {
    let partyDetail = {
      ...partiesPersonForm,
      ptsIncPersonId: sp.ptsIncPersonId,
      ptsPersonId: sp.ptsPersonId,
      values: {
        ...partiesPersonForm.values,
        partyType: sp.personDetail.values.partyType,
      },
    };

    const requestBody = {
      ptsIncidentId,
      ptsPersonId: partyDetail.ptsPersonId,
      ptsIncPersonId: partyDetail.ptsIncPersonId,
      partyType: partyDetail.values.partyType,
      personDetail: partyDetail,
      contactDetail: partiesContactForm,
      employmentDetail: partiesEmploymentForm,
      otherDetail: partiesOthersForm,
      user: user,
    };

    if (
      partiesPersonForm.values?.partyType?.toLowerCase() === 'vic' &&
      Object.keys(offensesMPBasicForm.values).length !== 0 &&
      offensesMPBasicForm.changes === true
    ) {
      requestBody.mpBasicFormDetails = offensesMPBasicForm;
      requestBody.mpRelatedRecords = mpRelatedRecords;
    }

    return requestBody;
  });

  await asyncForEach(requestArray, async (d) => {
    let response = await service.patch(null, d);
    // console.log('RESPONSE HERE: ', response);
  });
};

export const removeIncidentEvidencePartiesFromStore = (ptsEvidencePersonId) => {
  return async (dispatch) => {
    let { selectedIncidentEvidence } = store.store.getState().incident;

    const updatedPartiesList = selectedIncidentEvidence.evidenceParties.filter(
      (party) => party.ptsEvidencePersonId != ptsEvidencePersonId
    );

    selectedIncidentEvidence.evidenceParties = updatedPartiesList;

    dispatch({
      type: SET_SELECTED_INCIDENT_EVIDENCE,
      payload: selectedIncidentEvidence,
    });
  };
};

export const getIncidentNIBRSerrors = async (incidentId, dispatch) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('incibrs');
    service.timeout = 20000;

    const response = await service.find({
      query: {
        $select: [
          'ptsIBRSID',
          'ptsIncidentID',
          'NIBRSCode',
          'IBRSID',
          'InputField',
          'Title',
          'Message',
          'View',
          'ptsOffenseID',
          'ptsIncPersonID',
          'ptsArresteeID',
          'ptsVictimID',
          'ptsSubjectID',
          'incIncidentPropertyID',
          'Stepper',
          'PropertyType',
          'PartyType',
          'isErrorAvailable',
          'ErrorIdentifier',
        ],
        $limit: 1000,
        ptsIncidentID: incidentId,
        isErrorAvailable: true,
        IsDeleted: false,
      },
    });

    if (response?.data?.length) {
      setNibrsErrorsForIncident(
        incidentId,
        '',
        response?.data,
        generateHandleEditOffense,
        generateHandleEditIncident,
        setValidationErrors,
        setNibrsErrors,
        dispatch
      );
    }
  } catch (err) {
    console.log(err);
  }
};

export const createCustomIncidentID = async () => {
  const client = store.store.getState().websocket.websocket;
  const agencyID = store.store.getState().user.userAgency;

  const data = { EntityName: 'Cases', AgencyID: agencyID };

  const customIdService = client.service('custom-ids');
  customIdService.timeout = 20000;

  let checkedIncidentId;
  let incidentId;

  while (checkedIncidentId !== 'ID Not Found') {
    try {
      incidentId = await customIdService.create(data);

      checkedIncidentId = await customIdService.get({
        entity: 'Cases',
        id: incidentId,
      });
    } catch (err) {
      console.log(err);
      break;
    }
  }

  return incidentId;
};

export const checkIncidentNumberExist = async (customId, currentIncidentId) => {
  if (!customId) return false;
  const client = store.store.getState().websocket.websocket;

  const service = client.service('incincident');
  service.timeout = 20000;
  let query = {
    IncidentID: customId,
  };
  if (currentIncidentId) {
    query = {
      ...query,
      ptsIncidentID: {
        $ne: currentIncidentId,
      },
    };
  }
  const incidentDetails = await service.find({
    query: query,
  });

  return incidentDetails && incidentDetails.data && incidentDetails.data.length > 0 ? true : false;
};
