import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Fab } from '@material-ui/core';
import { Tooltip } from '@mui/material';

import store from '../config/configureStore';
import { getService } from '../reducers/service';
import htmlForPrinting from './htmlForPrinting';
import TagTypesIcon from 'components/TagTypesIcon';
import { showPersonTagInfo } from 'reducers/PersonDialogsReducer';
import { formatSaveDate } from 'reducers/TimeReducer';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

export const excludeIncidentFromIBRSList = (offenses) => {
  let notReportableOffenseCount = 0;
  offenses.forEach((o) =>
    o?.FBICode?.[0].FBICode === '0' || o?.IsExcluded === true
      ? (notReportableOffenseCount += 1)
      : notReportableOffenseCount
  );

  return offenses.length === notReportableOffenseCount ? true : false;
};

export const getCityName = (ptsCityId) => {
  const codeCities = store.store.getState().dictionary.codeCities;
  const city = codeCities.find((city) => city.ptsCityID === ptsCityId);

  return city ? city.CityDescription : '';
};

export function getServerDateTime() {
  const state = store.store.getState();
  const { timeDiff } = state.config;
  const time = new Date().getTime() - timeDiff;
  return new Date(time);
}

export function formatDate(date) {
  if (!date) return '-';
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L LT');
}

export function formatSearchDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.utc().format('L LT');
}

export function formatIfDate(str) {
  if (isValidDate(str)) return formatDate(str);
  return str;
}

export function getTime(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('LT');
}

export function getDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L');
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

export function getPassedTime(date) {
  if (typeof date !== 'string' || date.length > 24) return 0;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return 0;
  const currentDate = getServerDateTime();
  return -m.diff(currentDate, 'minutes');
}

export function minsToHours(min) {
  const mins = min % 60;
  const hours = Math.floor(min / 60);
  return ('00' + hours).substr(-2) + ':' + ('00' + mins).substr(-2);
}

/** Fixes issue in some dates in database - adds Z at the end */
function addZtoDate(date) {
  return date + (date.substr(-1) === 'Z' ? '' : 'Z');
}

export const formatDateFrom = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' 00:00:00.0';
};

export const formatDateTo = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' 23:59:59.0';
};

/* Used in styling flex forms */
export function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

/* Used in styling flex forms */
export function getRowStyle() {
  return {
    margin: `0 -4px 8px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: 500,
    maxWidth: 'calc(100% + 8px)',
  };
}

/* Used in styling flex forms */
export function get100prStyle() {
  return {
    width: '100%',
    margin: `0 4px 8px`,
  };
}

/* Reorder list used in drag and drop ordered list */
export function reorderDnd(arr, res) {
  if (!arr) return;
  const from = res.source.index;
  const to = res.destination.index;
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

export function removeFromArr(arr, val) {
  const idx = arr.indexOf(val);
  arr.splice(idx, 1);
  return arr;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function passValidationErrors(UserName, pass) {
  const errors = [];
  if (UserName === pass) errors.push('Cannot be the same as User ID');
  if (pass.length < 8) errors.push('Must be at least 8 characters');
  if (pass.length > 128) errors.push('Password is too long');
  if (pass.search(/[a-z]/) < 0) errors.push('Lower case character missing');
  if (pass.search(/[A-Z]/) < 0) errors.push('Upper case character missing');
  if (pass.search(/[0-9]/) < 0) errors.push('Include at least one digit');
  // if (pass.search(/[!@#\$%\^&\*\.,\-+!<>=_\(\)\[\]{};:\|\?]/) < 0)
  //   errors.push('Special character missing or invalild');

  return errors;
}

export function genColHeaderName(dbColName) {
  return dbColName
    .replace(/([a-z\d])([A-Z])/g, '$1' + ' ' + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + ' ' + '$2');
}

export const isProduction = process.env.NODE_ENV !== 'development';
export const isDevelopment = process.env.NODE_ENV === 'development';

/* Show testing functionality: 0 - production only, 1 - some visible, 2 - all visible */
export function getTestingLevel() {
  return parseInt(process.env.REACT_APP_TESTING_LEVEL) || 0;
}

export function getInitials(status, statusDescription) {
  if (!status) return '';
  if (statusDescription) {
    const words = statusDescription.split(' ');
    if (words.length > 1) {
      const word1 = words[0][0] || '';
      const word2 = words[words.length - 1][0] || '';
      return word1 + word2;
    }
  }
  const words2 = status.split('-');
  if (words2.length > 1) {
    const word1 = words2[0][0] || '';
    const word2 = words2[1][0] || '';
    return word1 + word2;
  }
  let nw = '';
  for (let i = 0; i < status.length; i++) {
    const ch = status[i];
    if (i === 0) {
      nw += ch;
    } else if (!isVowel(ch) && nw.length < 2) nw += ch;
  }
  if (nw.length === 2) return nw;
  return status.substr(0, 2);
}

export function isVowel(char) {
  if (char.length === 1) {
    return /[aeiouyAEIOUY]/.test(char);
  }
}

export function groupAndSort(arr, sortBy, groupBy, sortDirection = 'ASC', groupDirection = 'ASC') {
  const groups = arr
    .reduce((res, ev) => {
      const key = ev[groupBy];
      if (res.indexOf(key) === -1) res.push(key);
      return res;
    }, [])
    .sort((a, b) => ((groupDirection === 'ASC' ? a > b : a < b) ? 1 : -1));
  let result = [];
  groups.forEach((val) => {
    const group = arr.filter((obj) => obj[groupBy] === val);
    const sorted = sortObjArr(group, sortBy, sortDirection);
    result = [...result, ...sorted];
  });
  return result;
}

export function sortObjArr(arr, sortBy, sortDirection = 'ASC') {
  if (!Array.isArray(arr)) return arr;
  const newArr = [...arr];
  newArr.sort((a, b) => {
    let valA = a[sortBy];
    let valB = b[sortBy];
    if (valA === null) valA = '';
    if (valB === null) valB = '';
    if (isValidDate(valA) && isValidDate(valB)) {
      if (sortDirection === 'ASC') {
        return moment(valB).valueOf() - moment(valA).valueOf();
      } else {
        return moment(valA).valueOf() - moment(valB).valueOf();
      }
    } else {
      if (typeof valA === 'string' && typeof valB === 'string') {
        valA = valA.toLocaleLowerCase();
        valB = valB.toLocaleLowerCase();
      }
      if (sortDirection === 'ASC') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    }
  });
  return newArr;
}

export const asyncForEach = async (array, callback) => {
  if (!array) return;
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/** Used in processing input phone number to format (123) 123-4567 ext 890 */
export const processInputPhoneNo = (value) => {
  const maxLength = 24;
  let digits = '';
  value.split('').forEach((ch) => {
    const reg = /^\d+$/;
    if (ch.match(reg)) digits += ch;
  });
  const digitsArr = digits.split('');
  const len = digitsArr.length;
  len > 0 && digitsArr.splice(0, 0, '(');
  len > 3 && digitsArr.splice(4, 0, ') ');
  len > 6 && digitsArr.splice(8, 0, '-');
  len > 10 && digitsArr.splice(13, 0, ' ext ');
  const output = digitsArr.join('').substr(0, maxLength);
  return output;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const areObjEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

export const tableComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const printRef = (ref, title) => {
  const content = ref.current;
  let html = htmlForPrinting(content.innerHTML, `${title}`);
  const pri = document.getElementById('print-iframe').contentWindow;
  pri.document.open();
  pri.document.write(html);
  pri.document.close();
  pri.focus();
  pri.print();
};

export const getTableComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => tableComparator(a, b, orderBy)
    : (a, b) => -tableComparator(a, b, orderBy);
};

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const initNullObj = (arr) =>
  arr.reduce((res, val) => {
    res[val] = null;
    return res;
  }, {});

/**
 * The insertText() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {string} text to modify.
 * @param {number} position Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
export const insertText = (string, position, newSubStr) => {
  return string.slice(0, position) + newSubStr + string.slice(position);
};

export const getExcerpt = (text, length = 60) => {
  text = text.replace(/\n/g, ' ');
  if (text.length > length) {
    text = text.substr(0, length) + '...';
  }
  return text;
};

/**
 * Convert integer number to byte string
 * @param {*} no - number to convert
 * @param {*} pad number of total string length e.g. (1, 4) = "0001" [default 8]
 */
export const decBin = (no, pad = 8) => {
  if (no < 0) {
    no = 0xffffffff + no + 1;
  }
  return parseInt(no, 10).toString(2).padStart(pad, '0');
};

/**
 * returns array of filtered results - designed to work witgh DataGrid or XGrid widgets
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} cols - column definitions (strings accepted)
 * @returns [] filtered rows
 */
export const filterResults = (filter, rows, cols) => {
  if (!filter) return rows;
  filter = filter.toLocaleLowerCase();
  const result = [];
  rows.forEach((row) => {
    let match = false;
    for (let i = 0; i < cols.length; i++) {
      const col = typeof cols[i] === 'string' ? cols[i] : cols[i].field;
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/**
 * Advanced version of filterResults - accepts multiple words
 *
 * @param {string} filter single or multiple words 'tango inservice'
 * @param {array} rows data to be filtered
 * @param {array} cols - column definitions (strings accepted)
 * @returns [] filtered rows
 */
export const advancedFilter = (filter, rows, cols) => {
  const filters = filter
    .split(' ')
    .map((w) => w.trim())
    .filter((w) => Boolean(w));
  if (filters.length === 1) return filterResults(filter.trim(), rows, cols);
  const filteredRows = applyFilter(rows, filters, cols);
  return filteredRows;
};

const applyFilter = (rows, filters, cols) => {
  const filteredRows = filterResults(filters[0], rows, cols);
  if (filters.length === 1) {
    return filteredRows;
  }
  const newFilters = [...filters];
  newFilters.shift();
  return applyFilter(filteredRows, newFilters, cols);
};

/**
 * returns array of filtered results - simple definition
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} columns - array of names of ['name', 'ptsPersonID']
 */
export const filterArr = (filter, arr, columns) => {
  filter = filter.toLocaleLowerCase();
  const result = [];
  arr.forEach((row) => {
    let match = false;
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/**
 * returns filtered object, accepts multiple words in filter
 *
 * @param {array} arr array of objects to filter
 * @param {string|array} keys search text
 * @param {string|array} filter
 * @returns {array}
 * */
export const filterObj = (arr, keys, filter) => {
  if (!arr || !filter) return arr;
  let filterArr;
  if (typeof filter === 'string') {
    filterArr = filter.split(/\s+/).map((opt) => opt.toLowerCase());
  } else {
    filterArr = filter;
  }
  let newArr;
  filterArr.forEach((f) => {
    const opts = newArr ? newArr : arr;
    newArr = opts.filter((o) => {
      const str =
        typeof keys === 'string' ? o[keys] : keys.reduce((res, key) => res + o[key] + ' ', '');
      return str.toLowerCase().indexOf(f) !== -1;
    });
  });
  return newArr;
};

export function arraysWithSameValues(array1, array2) {
  const arr1 = [...array1],
    arr2 = [...array2];
  const len1 = arr1.length,
    len2 = arr2.length;
  if (len1 !== len2) return false;
  arr1.sort();
  arr2.sort();
  for (let i = 0; i < len1; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function addCreatedBy(data) {
  const state = store.store.getState();
  const Username = state?.user?.userData?.user?.Username;
  const exportData = { ...data };
  if (Username) {
    exportData.CreatedBy = Username;
  }
  return exportData;
}

export function addUpdatedBy(data) {
  const state = store.store.getState();
  const Username = state?.user?.userData?.user?.Username;
  const exportData = { ...data };
  if (Username) {
    exportData.UpdatedBy = Username;
  }
  return exportData;
}

export function addCreatedUpdatedBy(data) {
  const state = store.store.getState();
  const Username = state?.user?.userData?.user?.Username;
  const exportData = { ...data };
  if (Username) {
    exportData.CreatedBy = Username;
    exportData.UpdatedBy = Username;
  }
  return exportData;
}

export async function fetchWithTimeout(resource) {
  const { serviceTimeout } = settings;
  const options = { timeout: serviceTimeout };
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), serviceTimeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

/**
 * Combination of search and filter. Takes text phrase
 * returns obj { searchTerm: 'longestWord', filters: ['some', 'words'] }
 */
export const searchFilter = (text) => {
  const res = {
    searchTerm: '',
    filters: null,
  };
  if (!text) return res;
  const words = text.split(/\s+/).map((opt) => opt.toLowerCase());
  if (!words.length) {
    res.searchTerm = '';
    res.filters = null;
  } else if (words.length === 1) {
    res.searchTerm = words[0];
    res.filters = null;
  } else {
    const longestIdx = words.reduce(
      (result, val, idx) => (val.length > words[result].length ? idx : result),
      0
    );
    const longestWord = words[longestIdx];
    words.splice(longestIdx, 1);
    res.searchTerm = longestWord;
    res.filters = words;
  }
  return res;
};

/**
 * get the category of the dictionary code fields
 */
export const getCategoryOfCode = (codesArray, inputValue = '', serviceName) => {
  const state = store.store.getState();
  let codes = [];
  if (!codesArray && serviceName) {
    const dictionary = state.dictionary;
    codes = dictionary[serviceName] || [];
  } else {
    codes = codesArray;
  }
  const categoryObj = codes.find((c) => c.Code === inputValue) || '';

  return categoryObj ? categoryObj?.Category?.toLowerCase() : '';
};

/**
 * get the code of the dictionary fields based on category
 */
export const getCodeofCategory = (codesArray, inputValue) => {
  const codeObj = codesArray.find((c) => c.Category === inputValue);

  return codeObj ? codeObj.Code : '';
};

/**
 * get the description of the dictionary fields based on code
 */
export const getDescriptionofCode = (codesArray, inputValue, serviceName = null) => {
  const state = store.store.getState();
  let codes = [];
  if (!codesArray && serviceName) {
    const dictionary = state.dictionary;
    codes = dictionary[serviceName] || [];
  } else {
    codes = codesArray;
  }
  const codeObj = codes.find((c) => c.Code === inputValue);

  return codeObj ? codeObj.Description : '';
};

export const getOptionFromCode = (codesArray, inputValue, serviceName) => {
  const state = store.store.getState();
  let codes = [];
  if (!codesArray && serviceName) {
    const dictionary = state.dictionary;
    codes = dictionary[serviceName] || [];
  } else {
    codes = codesArray;
  }
  const categoryObj =
    codes.find((c) => c?.Code?.toLocaleLowerCase() === inputValue.toLocaleLowerCase()) || '';

  return categoryObj ? categoryObj : {};
};
/**
 * get the value of some dictionary table field based on some other field value
 */
export const getDataOfDictionaryOptions = (arrayName, inputField, outputField, inputValue) => {
  const dictionary = store.store.getState().dictionary;
  const codesArray = dictionary[arrayName];
  const codeObj = codesArray.find((c) => c[inputField] === inputValue);

  return codeObj ? codeObj[outputField] : '';
};

/**
 * render icon for codeTagTypes
 * icon = 0 - default icon
 * size = '' - default size
 * length = '20px' - default length
 * data = {} - icon data
 * personRecord = false - if true, show person tag dialog from record
 */
const showPersonTagDialog = (data) => {
  if (data === {}) return;
  store.store.dispatch(showPersonTagInfo(data));
};
export const CodeTagTypeIconRender = (
  icon = 0,
  size = '',
  length = '20px',
  data = {},
  personRecord = false
) => {
  const TagIcon = icon ? TagTypesIcon[icon] : TagTypesIcon[0];

  return (
    <Tooltip title={data.typeDescription || ''}>
      <Fab
        className="ml-1 mr-1 mt-2 mb-2"
        size={personRecord ? size : ''}
        style={
          personRecord ? {} : { width: '32px', height: '10px', minWidth: '24px', padding: '0px' }
        }
        color="secondary"
        onClick={() => showPersonTagDialog(data)}>
        <TagIcon style={{ width: `${length}`, height: `${length}`, fill: '#ffffff' }} />
      </Fab>
    </Tooltip>
  );
};

export const IsJuvenile = (type, aditionalData) => {
  const state = store.store.getState();
  const agencySettings = state.agencySettings.settings;
  const agencyID = store.store.getState().user.userAgency;
  const juvenileAgeLimit =
    agencySettings.find(
      (ag) => ag.Path?.includes(agencyID || '') && ag.CDRKey === 'Oldest Juvenile'
    )?.ValueNumeric || 17;

  const { dob, age, occurred } = aditionalData;
  const userdbo = dob ? dob : age ? calculateDob(age) : null;
  if (userdbo) {
    if (type === 'parties' && !occurred) return false;

    const calculatedAge = getAge(type === 'parties' ? occurred : new Date(), userdbo);
    return calculatedAge <= juvenileAgeLimit;
  }
  return false;
};

const getAge = (date, dob) => {
  const diff = new Date(date) - new Date(dob);
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
const calculateDob = (age) => {
  const currentDate = new Date();
  const birthYear = currentDate.getFullYear() - age;
  const birthDate = new Date(birthYear, currentDate.getMonth(), currentDate.getDate());
  return birthDate;
};

export const getJuvenileReportValues = (incidentDetails, attatchedParties, callingFrom) => {
  const state = store.store.getState();
  const agencyID = store.store.getState().user.userAgency;
  const agencySettings = state.agencySettings.settings;
  const juvenileAgeLimit =
    agencySettings.find(
      (ag) => ag.Path?.includes(agencyID || '') && ag.CDRKey === 'Oldest Juvenile'
    )?.ValueNumeric || 17;

  const allowedPartyTypes = agencySettings.filter(
    (item) => item.Path?.includes(agencyID || '') && item.CDRKey === 'Allowed Parties'
  );

  const parsedAllowedPartyTypes = JSON.parse(allowedPartyTypes[0]?.ValueJson || '[]');

  if (parsedAllowedPartyTypes.length === 0) return false;
  if (callingFrom === 'incident') {
    attatchedParties = attatchedParties?.filter(
      (item) => item.PartyType?.toLocaleLowerCase() === 'person'
    );
  }
  if (!Array.isArray(attatchedParties) && attatchedParties.length === 0) return false;
  let flag = false;
  for (let party of attatchedParties) {
    let age = 0;
    let birthDate = 0;
    let partyType = null;
    if (callingFrom === 'incident') {
      age = party.Age;
      birthDate = party.DOB;
      partyType = party.RelationshipCode;
    } else {
      age = party.personDetail.values.age;
      birthDate = party.personDetail.values.birthdate;
      partyType = party.personDetail.values.partyType;
    }
    const userAge = age
      ? age
      : birthDate
      ? getAge(incidentDetails['occurred'] || incidentDetails['Occurred'], birthDate)
      : 0;

    const category = getCategoryOfCode(null, partyType, 'codePartyRelationship');

    if (!userAge || !category) {
      continue;
    }

    if (
      parsedAllowedPartyTypes.findIndex(
        (item) => item.Category?.toLocaleLowerCase() === category
      ) !== -1 &&
      userAge <= juvenileAgeLimit
    ) {
      flag = true;
      break;
    }
  }

  return flag;
};

export function deepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return obj1 === obj2;
  }

  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check the values for each key
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const makeAddress = (street, city, state, zip) => {
  const address = `${street ? street + ',' : ''} ${city ? city + ',' : ''} ${state ? state : ''} ${
    zip ? zip : ''
  }`;
  return address;
};

/* Multiple Attachment download in .zip file */
export const downloadAttachmentsAsZip = async (
  attachments,
  fileName = 'Attachments',
  objectField = 'FileObject',
  typeField = 'FileType',
  nameField = 'FileName'
) => {
  const zip = new JSZip();

  // Get file objects to download
  const service = getService('rms-attachments-download');
  let data = await service.find({
    query: {
      attachments: attachments,
    },
  });

  // Loop through each attachment
  for (const attachment of data) {
    const blob = new Blob([attachment[objectField]], { type: attachment[typeField] });
    // Add the attachment to the zip using the provided filename
    zip.file(attachment[nameField], blob, { binary: true });
  }

  const content = await zip.generateAsync({ type: 'blob' }); // Generate the zip file

  // Customize the zip filename if desired
  const customFilename = fileName; // Replace with your desired name

  // Trigger the download using saveAs
  saveAs(content, customFilename);
};

export const formatPersonFullName = (person = {}) => {
  const lastname = person.LastName || person.lastname || person.lastName || '';
  const firstname = person.FirstName || person.firstname || person.firstName || '';
  const middleName = person.MiddleName || person.middlename || person.middleName || '';

  return `${lastname}${lastname && (firstname || middleName) ? ', ' : ''}${firstname || ''}${
    firstname && middleName ? ' ' : ''
  }${middleName}`;
};
export const checkJsonValidityAndParse = (val) => {
  try {
    const data = JSON.parse(val);
    return data;
  } catch (err) {
    return false;
  }
};

export const getCodeForMultiSelect = (values, name = 'Code') => {
  if (!values || !Array.isArray(values) || values.length === 0) return null;
  const codes = values.map((item) => item[name]);
  return JSON.stringify(codes);
};
export const pasredValuesForMultiSelectDropdown = (value, options) => {
  const arr = checkJsonValidityAndParse(value);
  if (!arr || !Array.isArray(arr)) return null;
  const mapedArr = arr.map((item) => {
    const obj = getOptionFromCode(null, item, options);
    return obj;
  });
  return mapedArr;
};

export const preparePrintDataForIncident = () => {
  const userName = store.store.getState().user.userData?.user.FullName;
  const incidentState = store.store.getState().incident;
  const incidentData = incidentState.incident;
  const incBodyStateData = store.store.getState().uiList.incListBodyState;
  const { parties, properties, offenses, evidence, narratives, addresses } = incBodyStateData;

  let partiesData = parties.map((p) => {
    let data = {
      PartyType: 'Person',
      PartyID: p?.ptsIncPersonId,
      RelationshipDescription: p?.partyType,
      PartyName: p?.personFullName,
      isArrestee: p?.availableOffenses?.length > 0 ? true : false,
      MissingPerson: p?.isMP,
      isDVVictim: p?.dvVictims?.length > 0 ? true : false,
    };

    return data;
  });

  addresses.forEach((a) => {
    if (a?.isVictim) {
      let data = {
        PartyType: 'Place',
        PartyID: a?.ptsPlaceId,
        RelationshipDescription: a?.partyType,
        PartyName: a?.PlaceName,
        isArrestee: false,
        MissingPerson: false,
        isDVVictim: false,
      };

      partiesData.push(data);
    }
  });

  const offensesData = offenses.map((o) => {
    let data = {
      ptsOffenseID: o?.ptsOffenseId,
      statute: o?.statute,
    };

    return data;
  });

  const propertiesData = properties.map((p) => {
    let data = {
      incIncidentPropertyID: p?.incIncidentPropertyId,
      PropertyType: p?.parentType,
      PropertyDescription: p?.description,
    };

    return data;
  });

  const narrativesData = narratives.map((n) => {
    let data = {
      ptsNarrativeID: n?.ptsNarrativeId,
      NarrativeType: n?.narrativeType,
      NarrativeTitle: n?.narrativeTitle,
    };

    return data;
  });

  const evidencesData = evidence.map((e) => {
    let data = {
      ptsEvidenceID: e?.ptsEvidenceId,
      EvidenceID: e?.evidenceId,
      EvidenceType: e?.itemEvidenceType,
      Category: e?.evidenceType,
      Description: e?.description,
    };

    return data;
  });

  let allAttachments = prepareAllAttachmentsData();

  return {
    ptsIncidentId: incidentData.ptsIncidentId,
    agencyId: incidentData.AgencyId,
    officer: userName,
    parties: partiesData || [],
    offenses: offensesData || [],
    properties: propertiesData || [],
    narratives: narrativesData || [],
    evidences: evidencesData || [],
    customId: incidentData.CustomId,
    attachments: allAttachments || [],
  };
};

/**
 * Helper function for preparing attachments data for report printing
 */
export const prepareAllAttachmentsData = () => {
  const incidentState = store.store.getState().incident;
  const { parties, properties, evidences, narratives } = incidentState;
  const incAttachments = store.store.getState().attachments.incAttachments;

  let allAttachments = [];

  if (incAttachments.length > 0) {
    incAttachments.map((ia) => allAttachments.push(ia));
  }

  if (parties.length > 0) {
    parties.map((p) => {
      if (p?.personDetail?.values?.attachments) {
        let partiesAttachments = p?.personDetail?.values?.attachments;
        partiesAttachments.map((pa) => allAttachments.push(pa));
      }
    });
  }

  if (properties.length > 0) {
    properties.map((pr) => {
      if (pr?.propertyDetails?.values?.attachments) {
        let propertiesAttachments = pr?.propertyDetails?.values?.attachments;
        propertiesAttachments.map((pra) => allAttachments.push(pra));
      }
    });
  }

  if (narratives.length > 0) {
    narratives.map((n) => {
      if (n?.narrativeDetail?.values?.attachments) {
        let narrativeAttachments = n?.narrativeDetail?.values?.attachments;
        narrativeAttachments.map((na) => allAttachments.push(na));
      }
    });
  }

  if (evidences.length > 0) {
    evidences.map((e) => {
      if (e?.itemRecordDetail?.values?.attachments) {
        let evidenceAttachments = e?.itemRecordDetail?.values?.attachments;
        evidenceAttachments.map((ea) => allAttachments.push(ea));
      }
    });
  }

  return allAttachments;
};

export const validatePtsStatuteCodeOptions = (options, compareableDate, selectedValue = {}) => {
  if (!options || !compareableDate) return [];
  const newArr = options.filter((statute) => {
    const { EnactmentDate, RepealDate, IsActive, IsDeleted } = statute;
    const enactmentDate = new Date(EnactmentDate);
    const repealDate = RepealDate ? new Date(RepealDate) : null;
    const occurred = new Date(compareableDate);

    // Check if offense is valid
    return (
      selectedValue?.Code === statute.Code ||
      (IsActive &&
        IsDeleted === false &&
        (!enactmentDate || enactmentDate <= occurred) &&
        (repealDate === null || repealDate > occurred))
    );
  });
  return newArr;
};

export const formatWarrantDates = (data) => {
  const exportData = { ...data };

  exportData.BondDate = exportData?.BondDate ? formatSaveDate(exportData?.BondDate) : null;
  exportData.IssuedDate = exportData?.IssuedDate ? formatSaveDate(exportData?.IssuedDate) : null;
  exportData.DispositionDate = exportData?.DispositionDate
    ? formatSaveDate(exportData?.DispositionDate)
    : null;
  exportData.ArrestDate = exportData?.ArrestDate ? formatSaveDate(exportData?.ArrestDate) : null;
  exportData.OffenseDate = exportData?.OffenseDate ? formatSaveDate(exportData?.OffenseDate) : null;
  exportData.ServedByDate = exportData?.ServedByDate
    ? formatSaveDate(exportData?.ServedByDate)
    : null;
  exportData.ExpirationDate = exportData?.ExpirationDate
    ? formatSaveDate(exportData?.ExpirationDate)
    : null;
  exportData.CourtDate = exportData?.CourtDate ? formatSaveDate(exportData?.CourtDate) : null;

  return exportData;
};
export const covertObjectPropertyToNullValue = (obj, allowedProperties) => {
  // except the allowed properties make all other null
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (!allowedProperties.includes(key)) {
      newObj[key] = null;
    } else {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};
